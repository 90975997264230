import './../App.scss';
import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';

import Button from 'react-bootstrap/Button';
import Api from '../util/Api'
import { PATCH_AGENCY, AGENCIES_URL, LOGO_PICTURE_URL, TEAM_PICTURE_URL, OFFICE_PICTURE_URL } from '../util/Constant'

const schema = yup.object().shape({
    name: yup.string().required('Champs obligatoire'),
    address: yup.string().required('Champs obligatoire'),
    city: yup.string().required('Champs obligatoire'),
    url: yup.string().url('URL doit etre valide').required('Champs obligatoire'),
    ceo: yup.string().required('Champs obligatoire'),
    founding_year: yup.string().required('Format AAAA-MM-JJ'),
    effectif_total: yup.string().required('Champs obligatoire'),
    description: yup.string().required('Champs obligatoire')
});

export default function TabGeneral(props) {
    const isPitchichi = JSON.parse(localStorage.getItem('isPitchichi'))

    const [showSuccess, setShowSuccess] = useState(false)
    const [showError, setShowError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const [showModal, setShowModal] = useState(false)
    const [title, setTitle] = useState("")
    const [fromCase, setFromCase] = useState("");
    const [selectedFile, setSelectedFile] = useState("");
    const handleCloseModal = () => setShowModal(false);


    const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    const handleSubmitModal = (event) => {
        const formData = new FormData();
        formData.append("title", title);
        if (selectedFile) {
            formData.append("file", selectedFile);

        }
        event.preventDefault();
        switch (fromCase) {
            case "logo-modify":
                Api.patch(LOGO_PICTURE_URL(props.agency.slug), formData).then(res => {
                    props.onClick()
                    setShowModal(false)

                }).catch(err => {
                    alert(JSON.stringify(err?.response?.data))

                });
                break;
            case "logo-add":
                Api.post(LOGO_PICTURE_URL(props.agency.slug), formData).then(res => {
                    props.onClick()
                    setShowModal(false)

                }).catch(err => {
                    alert(JSON.stringify(err?.response?.data))

                });
                break;
            case "team-modify":
                Api.patch(TEAM_PICTURE_URL(props.agency.slug), formData).then(res => {
                    props.onClick()
                    setShowModal(false)

                }).catch(err => {
                    alert(JSON.stringify(err?.response?.data))

                });
                break;
            case "team-add":
                Api.post(TEAM_PICTURE_URL(props.agency.slug), formData).then(res => {
                    props.onClick()
                    setShowModal(false)

                }).catch(err => {
                    alert(JSON.stringify(err?.response?.data))

                });
                break;
            case "office-modify":
                Api.patch(OFFICE_PICTURE_URL(props.agency.slug), formData).then(res => {
                    props.onClick()
                    setShowModal(false)

                }).catch(err => {
                    alert(JSON.stringify(err?.response?.data))

                });
                break;
            case "office-add":
                Api.post(OFFICE_PICTURE_URL(props.agency.slug), formData).then(res => {
                    props.onClick()
                    setShowModal(false)

                }).catch(err => {
                    alert(JSON.stringify(err?.response?.data))

                });
                break;
            default:
                setErrorMessage("erreur")
                setShowModal(false)

                break
        }

    };

    const handleShowModal = (title, from) => {
        setFromCase(from)
        setShowModal(true)

        setTitle(title)
    };

    const handleDelete = (from) => {
        switch (from) {
            case "logo":
                Api.delete(LOGO_PICTURE_URL(props.agency.slug)).then(res => {
                    props.onClick()
                    setShowModal(false)

                }).catch(err => {
                    alert(JSON.stringify(err?.response?.data))

                });
                break;
            case "office":
                Api.delete(OFFICE_PICTURE_URL(props.agency.slug)).then(res => {
                    props.onClick()
                    setShowModal(false)

                }).catch(err => {
                    alert(JSON.stringify(err?.response?.data))

                });
                break;
            case "team":
                Api.delete(TEAM_PICTURE_URL(props.agency.slug)).then(res => {
                    props.onClick()
                    setShowModal(false)

                }).catch(err => {
                    alert(JSON.stringify(err?.response?.data))

                });
                break;
            default:
                setErrorMessage("erreur")
                setShowModal(false)
                break
        }
    };

    const fileChangedHandler = (event) => {
        setSelectedFile(event.target.files[0])
    }

    const onSubmit = (data) => {
        if (data.secondary_cities.length > 1 && typeof data.secondary_cities !== "object") {
            var array = data.secondary_cities.split(',');
            data.secondary_cities = array
        } else if (data.secondary_cities.length === 0) {
            data.secondary_cities = []
        }

        if (props.agency) {
            Api.patch(PATCH_AGENCY(props.agency.slug), data).then(res => {
                setShowSuccess(true)
                setTimeout(() => {
                    setShowSuccess(false)
                  }, 1500);
            }).catch(err => {
                alert(JSON.stringify(err?.response?.data))

            });
        } else {
            Api.post(AGENCIES_URL, data).then(res => {
                setShowSuccess(true)
                setTimeout(() => {
                    setShowSuccess(false)
                  }, 1500);
            }).catch(err => {
                alert(JSON.stringify(err?.response?.data))

            });
        }

    };

    useEffect(() => {
        setValue("name", props.agency.name || "");
        setValue("holding_name", props.agency.holding_name || "");
        setValue("address", props.agency.address || "");
        setValue("city", props.agency.city || "");
        setValue("url", props.agency.url || "");
        setValue("ceo", props.agency.ceo || "");
        setValue("founding_year", props.agency.founding_year || "");
        setValue("description", props.agency.description || "");
        setValue("effectif_total", props.agency.effectif_total || "")
        setValue("secondary_cities", props.agency.secondary_cities || "")
        setValue("old_slug", props.agency.old_slug || "")

    }, [props.agency.old_slug, props.agency.address, props.agency.ceo, props.agency.city, props.agency.description, props.agency.effectif_total, props.agency.founding_year, props.agency.holding_name, props.agency.name, props.agency.secondary_cities, props.agency.url, setValue])


    return (
        <>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group row">

                    <label className="col-sm-3 col-form-label" htmlFor="name">Nom *</label>
                    <div className="col-sm-5">
                        <input type="text" className="form-control" id="" name="name" {...register("name")} />
                        <div className="required-error text-danger">{errors.name?.message}</div>
                    </div>

                </div>
                <div className="form-group row">
                    <label className="col-sm-3 col-form-label" htmlFor="holding_name">Nom du groupe</label>
                    <div className="col-sm-5">
                        <input type="text" className="form-control" id="" name="holding_name" {...register("holding_name")} />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-3 col-form-label" htmlFor="address">Adresse de l'agence *</label>
                    <div className="col-sm-5">
                        <input type="text" className="form-control" id="" name="address" {...register("address")} />
                        <div className="required-error text-danger">{errors.address?.message}</div>
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-3 col-form-label" htmlFor="city">Ville de référence *</label>
                    <div className="col-sm-5">
                        <input type="text" className="form-control" id="" name="city" {...register("city")} />
                        <div className="required-error text-danger">{errors.city?.message}</div>
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-3 col-form-label" htmlFor="secondary_cities">Villes secondaires</label>
                    <div className="col-sm-5">
                        <input type="text" className="form-control" id="" name="secondary_cities" {...register("secondary_cities")} placeholder="Séparer par une virgule (ex: Paris,Nantes)" />
                        <div className="required-error text-danger">{errors.secondary_cities?.message}</div>
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-3 col-form-label" htmlFor="url">Site web *</label>
                    <div className="col-sm-5">
                        <input type="text" className="form-control" id="" name="url" {...register("url")} />
                        <div className="required-error text-danger">{errors.url?.message}</div>
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-3 col-form-label" htmlFor="ceo">Board *</label>
                    <div className="col-sm-5">
                        <input type="text" className="form-control" id="" name="ceo" {...register("ceo")} placeholder="Séparer par une virgule (ex: André Ducasse,Thomas Sabatier)" />
                        <div className="required-error text-danger">{errors.ceo?.message}</div>
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-3 col-form-label" htmlFor="inputStfounding_yearate">Année de création *</label>
                    <div className="col-sm-5">
                        <input type="text" className="form-control" id="" name="founding_year" {...register("founding_year")} />
                        <div className="required-error text-danger">{errors.founding_year?.message}</div>
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-3 col-form-label" htmlFor="effectif_total">Effectif total de l'Agence *</label>
                    <div className="col-sm-5">
                        <input type="number" className="form-control" id="" name="effectif_total" {...register("effectif_total")} />
                        <div className="required-error text-danger">{errors.effectif_total?.message}</div>
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-3 col-form-label" htmlFor="description">Description *</label>
                    <div className="col-sm-5">
                        <textarea className="form-control" id="exampleFormControlTextarea1" name="description" maxLength="340" rows="5" {...register("description")} ></textarea>
                        <div className="required-error text-danger">{errors.description?.message}</div>
                    </div>
                    <div className="col-sm-3">
                        <span>{watch("description")?.length} caractère{watch("description")?.length > 1 && <span>s</span>}</span>
                    </div>
                </div>
                {isPitchichi && <div className="form-group row">
                    <label className="col-sm-3 col-form-label" htmlFor="description">Old slug</label>
                    <div className="col-sm-5">
                        <input className="form-control" id="exampleFormControlTextarea1" name="oldslug" maxLength="340" rows="5" {...register("old_slug")}></input>
                        <div className="required-error text-danger">{errors.old_slug?.message}</div>
                    </div>
                </div>}
                <div className="d-flex mt-5 align-items-center">
                    <Button variant="outline-primary" type="submit" className="pitchichi2 px-5">Valider</Button>
                    {showSuccess && <span variant="success" className="pitchichi2 ml-3 text-success">Mise à jour effectuée</span>}
                </div>

            </form>
            {props.agency &&
                <Row className="mt-5">
                    <Col xs={4} className="d-flex align-items-center justify-content-between flex-column">
                        <h2>Logo</h2>
                        <div style={{ height: 200 }}>
                            <img src={props?.agency?.logo?.file} width="100%" height="100%" alt="" />
                        </div>
                        <div>
                            {props?.agency?.logo?.title}
                        </div>
                        <div className="mt-4">
                            {
                                props.agency.logo
                                    ?
                                    <div>
                                        <Button variant="outline-primary" onClick={() => handleShowModal(props?.agency?.logo?.title, "logo-modify")} size="sm" className="pitchichi">Modifier</Button>
                                        <Button variant="outline-danger" onClick={() => handleDelete("logo")} size="sm" className="ml-2 pitchichi">Supprimer</Button>
                                    </div>
                                    :
                                    <Button variant="outline-primary" onClick={() => handleShowModal(props?.agency?.logo?.title, "logo-add")} size="sm" className="pitchichi">Ajouter</Button>
                            }
                        </div>
                    </Col>
                    <Col xs={4} className="d-flex align-items-center justify-content-between flex-column">
                        <h2>Photo de bureau</h2>
                        <div style={{ height: 200 }}>
                            <img src={props?.agency?.office_picture?.file} width="100%" height="100%" alt="" />
                        </div>
                        <div>
                            {props?.agency?.office_picture?.title}
                        </div>
                        <div className="mt-4">
                            {
                                props.agency.office_picture
                                    ?
                                    <div>
                                        <Button variant="outline-primary" onClick={() => handleShowModal(props?.agency?.office_picture?.title, "office-modify")} size="sm" className="pitchichi">Modifier</Button>
                                        <Button variant="outline-danger" onClick={() => handleDelete("office")} size="sm" className="ml-2 pitchichi">Supprimer</Button>
                                    </div>
                                    :
                                    <Button variant="outline-primary" onClick={() => handleShowModal(props?.agency?.office_picture?.title, "office-add")} size="sm" className="pitchichi">Ajouter</Button>
                            }
                        </div>
                    </Col>
                    <Col xs={4} className="d-flex align-items-center justify-content-between flex-column">
                        <h2>Photo d'équipe</h2>

                        <div style={{ height: 200 }}>
                            <img src={props?.agency?.team_picture?.file} width="100%" height="100%" alt="" />
                        </div>
                        <div>
                            {props?.agency?.team_picture?.title}
                        </div>
                        <div className="mt-4">
                            {
                                props.agency.team_picture
                                    ?
                                    <div>
                                        <Button variant="outline-primary" onClick={() => handleShowModal(props?.agency?.team_picture?.title, "team-modify")} size="sm" className="pitchichi">Modifier</Button>
                                        <Button variant="outline-danger" onClick={() => handleDelete("team")} size="sm" className="ml-2 pitchichi">Supprimer</Button>
                                    </div>
                                    :
                                    <Button variant="outline-primary" onClick={() => handleShowModal(props?.agency?.team_picture?.title, "team-add")} size="sm" className="pitchichi">Ajouter</Button>
                            }
                        </div>
                    </Col>
                </Row>
            }


            <Modal size="lg" show={showModal} onHide={handleCloseModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Ajouter une image</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmitModal}>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label" htmlFor="inputState">Fichier</label>
                            <div className="col-sm-4">
                                <input type="file" onChange={fileChangedHandler} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label" htmlFor="inputState">Titre</label>
                            <div className="col-sm-8">
                                <input type="text" className="form-control" id="" onChange={(e) => setTitle(e.target.value)} value={title} />
                            </div>
                        </div>

                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Annuler
                    </Button>
                    <Button variant="primary" onClick={handleSubmitModal}>
                        Valider
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

