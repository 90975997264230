import jwt_decode from 'jwt-decode';

export function isTokenExpired(token) {
    if (token != null) {

        let decoded = jwt_decode(token);
        let expirationDate = new Date(decoded.exp * 1000);
        if (new Date() > expirationDate) {
            return true;
        }
        return false;
    }
    return true;
}
