import React, { useState, useEffect } from "react";
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { RESET_PASSWORD_URL } from '../util/Constant'
import './ResetPassword.scss';
import logo from '../Assets/logo-main.svg'
import { useHistory } from "react-router-dom";

export default function ResetPassword(props) {
    const [password1, setpassword1] = useState(null)
    const [password2, setpassword2] = useState(null)
    const [showModal, setShowModal] = useState(false)
    const [error, setError] = useState()

    const history = useHistory();

    const handleCloseModal = () => {
        setShowModal(false)
        history.push("/");
    };
    const handlePasswordReset = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("uid", props.match.params.uid);
        formData.append("token", props.match.params.token);
        formData.append("new_password1", password1);
        formData.append("new_password2", password2);
        axios.post(RESET_PASSWORD_URL, formData).then(res => {
            setShowModal(true)
        }).catch(err => {
            setError(err.response.data.new_password2[0])
        });
        
    }

    const setPasswordOne = (e) => {
        setError()
        setpassword1(e.target.value)
    }

    const setPasswordTwo = (e) => {
        setError()
        setpassword2(e.target.value)
    }

    return (
        <div className="wrapper-reset text-center">
            <form className="form-reset" onSubmit={handlePasswordReset}>
                <img className="mb-4" src={logo} alt="" width="250" />
                <div className="form-group row">
                    <label className="col-3 col-form-label" htmlFor="inputName">Mot de passe</label>
                    <div className="col-8">
                        <input type="password" className="form-control" id="new_password1" onChange={(e) => setPasswordOne(e)} value={password1} />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-3 col-form-label" htmlFor="inputName">Vérification du mot de passe</label>
                    <div className="col-8">
                        <input type="password" className="form-control" id="new_password2" onChange={(e) => setPasswordTwo(e)} value={password2} />
                        <small id="passwordHelpBlock" class="form-text text-muted">
                        {error}
                        </small>
                    </div>
                </div>
                <button className="btn btn-lg btn-primary btn-block mt-3" disabled={!password1 || !password2} type="submit">Mettre à jour</button>
                <div className="text-primary mt-1">
                    <a href="/"><small>Retour à la page de connexion</small></a>
                </div>
            </form>
            <Modal size="lg" show={showModal} onHide={handleCloseModal} centered>

                <Modal.Body>
                   Votre mot de passe à bien été réinitialisé

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Retour à la page de connexion
                    </Button>

                </Modal.Footer>
            </Modal>
        </div>
    );

}





