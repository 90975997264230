import './../App.scss';
import React, { useState, useEffect } from "react";
import { LinkContainer } from 'react-router-bootstrap'
import Button from 'react-bootstrap/Button';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import hiddenPicto from "./../Assets/crosseye.svg"
import Form from "react-bootstrap/Form";

import Modal from 'react-bootstrap/Modal';
import Api from '../util/Api'
import { AGENCIES_URL, TEAM_PICTURE_URL, OFFICE_PICTURE_URL, LOGO_PICTURE_URL, PATCH_AGENCY } from '../util/Constant'

export default function Agencies() {
  const [agencies, setAgencies] = useState('')
  const [agencySlug, setAgencySlug] = useState('')
  const [selectedFile, setSelectedFile] = useState("");
  const [title, setTitle] = useState("");
  const [searchName, setSearchName] = useState('')

  const [nextPageUrl, setNextPageUrl] = useState('')
  const [previousPageUrl, setPreviousPageUrl] = useState('')

  const [showTeamModal, setShowTeamModal] = useState(false);
  const [showOfficeModal, setShowOfficeModal] = useState(false);
  const [showLogoModal, setShowLogoModal] = useState(false);

  const [url, setUrl] = useState(AGENCIES_URL)

  const handleCloseTeamModal = () => setShowTeamModal(false);
  const handleCloseOfficeModal = () => setShowOfficeModal(false);
  const handleCloseLogoModal = () => setShowLogoModal(false);


  const handleShowTeam = (slug) => {
    setShowTeamModal(true)
    setAgencySlug(slug)
  };

  const handleShowOffice = (slug) => {
    setShowOfficeModal(true)
    setAgencySlug(slug)
  };
  const handleShowLogo = (slug) => {
    setShowLogoModal(true)
    setAgencySlug(slug)
  };

  const handleSubmitTeamModal = event => {
    const formData = new FormData();
    formData.append("title", title);
    formData.append("file", selectedFile);
    event.preventDefault();
    Api.post(TEAM_PICTURE_URL(agencySlug), formData).then(res => {
      setShowTeamModal(false)
      getAgencies()
    }).catch(err => {
      alert(JSON.stringify(err?.response?.data))
    });
  }
  const handleSubmitLogoModal = event => {
    const formData = new FormData();
    formData.append("title", title);
    formData.append("file", selectedFile);
    event.preventDefault();
    Api.post(LOGO_PICTURE_URL(agencySlug), formData).then(res => {
      setShowLogoModal(false)
      getAgencies()
    }).catch(err => {
      alert(JSON.stringify(err?.response?.data))
    });
  }

  const handleSubmitOfficeModal = event => {
    const formData = new FormData();
    formData.append("title", title);
    formData.append("file", selectedFile);
    event.preventDefault();
    Api.post(OFFICE_PICTURE_URL(agencySlug), formData).then(res => {
      setShowOfficeModal(false)
      getAgencies()
    }).catch(err => {
      alert(JSON.stringify(err?.response?.data))
    });
  }

  const fileChangedHandler = (event) => {
    setSelectedFile(event.target.files[0])
  }

  const getAgencies = () => {
    Api.get(url, { params: { slug_ordering: true, name: searchName } })
      .then((response) => {
        setAgencies(response.data)
        setNextPageUrl(response.data.next)

      }).catch((err) => {
        alert(JSON.stringify(err?.response?.data))
      }).then(() => {

      })
  }

  const getAgenciesNext = (url) => {
    Api.get(url, { params: { slug_ordering: true} })
      .then((response) => {
        setUrl(url)
        setAgencies(response.data)
        setNextPageUrl(response.data.next)
        setPreviousPageUrl(response.data.previous)
        window.scrollTo(0, 0);
      })
      .catch((err) => {
        alert(JSON.stringify(err?.response?.data))
      })
  }

  const getAgenciesPrevious = (url) => {
    Api.get(url, { params: { slug_ordering: true} })
      .then((response) => {
        setUrl(url)
        setAgencies(response.data)
        setNextPageUrl(response.data.next)
        setPreviousPageUrl(response.data.previous)
        window.scrollTo(0, 0);
      })
      .catch(error => console.error(error))
  }

  const handleHideAgency = (agencySlug) => {
    const formData = new FormData();
    formData.append("hidden", true);
    Api.patch(PATCH_AGENCY(agencySlug), formData).then(res => {

      getAgencies()

    }).catch(err => {
      alert(JSON.stringify(err?.response?.data))
    });
  }

  const handleShowAgency = (agencySlug) => {
    const formData = new FormData();
    formData.append("hidden", false);
    Api.patch(PATCH_AGENCY(agencySlug), formData).then(res => {

      getAgencies()

    }).catch(err => {
      alert(JSON.stringify(err?.response?.data))
    });
  }


  useEffect(() => {
    getAgencies();
  }, [searchName]);
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <div className="btn-toolbar mb-2 mb-md-0">
          <div className="btn-group mr-2">
            <LinkContainer to="/agency">
              <Button variant="primary" >Ajouter une agence</Button>
            </LinkContainer>
          </div>
          <Form.Group className="" controlId="formAgenciesName">
            <Form.Control type="text" onChange={(e) => setSearchName(e.target.value)} value={searchName} autoComplete="off" placeholder="Rechercher par nom" />
          </Form.Group>
        </div>
      </div>


      <div className="row row-cols-3 row-cols-md-3 row-cols-lg-3 row-cols-xl-4">
        {agencies && agencies.results.map((agency) => (
          <div className="col mb-4" key={agency.slug}>
            <div className="card h-100">
              <div className="card-header-team" style={{ background: agency?.team_picture?.file !== undefined ? `url(${agency?.team_picture?.file})` : "#d0e4fc" }}></div>
              <div className="card-header-logo" style={{ backgroundImage: `url(${agency?.logo?.file})` }}></div>
              {agency.hidden && <div className="hidden-agency" style={{ backgroundImage: 'url(' + hiddenPicto + ')' }}></div>}
              <div className="card-body">
                <h5 className="card-title">{agency.name}</h5>
                <p className="card-text">{agency.city}</p>
                <div className="d-flex justify-content-around">
                  <div className="d-flex ">
                    <LinkContainer to={`/agency/${agency.slug}`}>
                      <Button variant="outline-primary" size="sm" className="pitchichi">Modifier</Button>
                    </LinkContainer>
                  </div>
                  <div className="d-flex ">
                    <DropdownButton variant="outline-warning" size="sm" className="rounded" title="Actions">
                      <Dropdown.Item onClick={() => handleShowLogo(agency.slug)}>Ajouter un logo</Dropdown.Item>
                      <Dropdown.Item onClick={() => handleShowTeam(agency.slug)}>Ajouter une photo d'équipe</Dropdown.Item>
                      <Dropdown.Item onClick={() => handleShowOffice(agency.slug)}>Ajouter une photo des locaux</Dropdown.Item>
                      {!agency.hidden ? <Dropdown.Item onClick={() => handleHideAgency(agency.slug)}>Cacher cette agence</Dropdown.Item> : <Dropdown.Item onClick={() => handleShowAgency(agency.slug)}>Montrer cette agence</Dropdown.Item>}
                    </DropdownButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="justify-content-md-end  justify-content-center">
        <Button variant="primary" className=" m-3" disabled={!previousPageUrl} onClick={() => getAgenciesPrevious(previousPageUrl)}>Page précédente</Button>
        <Button variant="primary" className=" m-3" disabled={!nextPageUrl} onClick={() => getAgenciesNext(nextPageUrl)}>Page suivante</Button>
      </div>

      <Modal show={showTeamModal} onHide={handleCloseTeamModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Ajouter une photo des locaux</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmitTeamModal}>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label" for="inputState">Fichier</label>
              <div className="col-sm-4">
                <input type="file" onChange={fileChangedHandler} />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label" for="inputState">Titre</label>
              <div className="col-sm-4">
                <input type="text" className="form-control" id="" onChange={(e) => setTitle(e.target.value)} value={title} />
              </div>
            </div>

          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseTeamModal}>
            Annuler
          </Button>
          <Button variant="primary" onClick={handleSubmitTeamModal}>
            Ajouter
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showOfficeModal} onHide={handleCloseOfficeModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Ajouter une photo d'équipe</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmitOfficeModal}>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label" for="inputState">Fichier</label>
              <div className="col-sm-4">
                <input type="file" onChange={fileChangedHandler} />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label" for="inputState">Titre</label>
              <div className="col-sm-4">
                <input type="text" className="form-control" id="" onChange={(e) => setTitle(e.target.value)} value={title} />
              </div>
            </div>

          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseOfficeModal}>
            Annuler
          </Button>
          <Button variant="primary" onClick={handleSubmitOfficeModal}>
            Ajouter
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showLogoModal} onHide={handleCloseLogoModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Ajouter un logo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmitLogoModal}>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label" for="inputState">Fichier</label>
              <div className="col-sm-4">
                <input type="file" onChange={fileChangedHandler} />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label" for="inputState">Titre</label>
              <div className="col-sm-4">
                <input type="text" className="form-control" id="" onChange={(e) => setTitle(e.target.value)} value={title} />
              </div>
            </div>

          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseLogoModal}>
            Annuler
          </Button>
          <Button variant="primary" onClick={handleSubmitLogoModal}>
            Ajouter
          </Button>
        </Modal.Footer>
      </Modal>


    </>
  );
}

