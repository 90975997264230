import './../App.scss';
import axios from 'axios'
import React, { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';

import Modal from 'react-bootstrap/Modal';
import Api from '../util/Api'
import { PRESS_URL, PRESS_LOGO_URL } from '../util/Constant'

export default function Press() {

  const [press, setPress] = useState([])
  const [pressId, setPressId] = useState('')
  const [showLogoModal, setShowLogoModal] = useState(false)
  const [showModifyModal, setShowModifyModal] = useState(false)
  const [showAddModal, setShowAddModal] = useState(false)

  const [selectedFile, setSelectedFile] = useState("");
  const [title, setTitle] = useState("");
  const [pressUrl, setPressUrl] = useState("");
  const [pressTitle, setPressTitle] = useState("");

  const handleShowLogoModal = (id) => {
    setShowLogoModal(true)
    setPressId(id)
  };

  const handleCloseLogoModal = () => {
    setShowLogoModal(false)
  }




  const handleSubmitLogoModal = event => {
    const formData = new FormData();
    formData.append("title", title);
    formData.append("file", selectedFile);
    event.preventDefault();
    Api.post(PRESS_LOGO_URL(pressId), formData).then(res => {
      setShowLogoModal(false)
      getPress()
    }).catch(err => {
      alert(JSON.stringify(err?.response?.data))
    });
  }



  const fileChangedHandler = (event) => {
    setSelectedFile(event.target.files[0])
  }

  const handleDeleteLogo = (id) => {
    Api.delete(PRESS_LOGO_URL(id)).then(res => {
      getPress()
    }).catch(err => {
      alert(JSON.stringify(err?.response?.data))
    });
  }

  const handleCloseModifyModal = () => {
    setShowModifyModal(false)
  }

  const handleSubmitModifyModal = event => {
    const formData = new FormData();
    formData.append("title", pressTitle);
    formData.append("url", pressUrl);
    event.preventDefault();
    Api.patch(PRESS_URL + (pressId) + '/', formData).then(res => {
      setShowModifyModal(false)
      getPress()
    }).catch(err => {
      alert(JSON.stringify(err?.response?.data))
    });
  }

  const handleShowModifyModal = (id, title, url) => {
    setPressUrl(url)
    setPressTitle(title)
    setPressId(id)
    setShowModifyModal(true)

  }

  const handleCloseAddModal = () => {
    setShowAddModal(false)
  }

  const handleSubmitAddModal = event => {
    const formData = new FormData();
    formData.append("title", pressTitle);
    formData.append("url", pressUrl);
    event.preventDefault();
    Api.post(PRESS_URL, formData).then(res => {
      setShowAddModal(false)
      getPress()
    }).catch(err => {
      alert(JSON.stringify(err?.response?.data))
    });
  }

  const handleShowAddModal = () => {
    setPressUrl("")
    setPressTitle("")
    setPressId("")
    setShowAddModal(true)
  }


  const handleDeleteCase = (id) => {
    Api.delete(PRESS_URL + (id) + '/').then(res => {
      getPress()
    }).catch(err => {
      alert(JSON.stringify(err?.response?.data))
    });
  }

  const getPress = () => {
    Api.get(PRESS_URL)
      .then((response) => {
        setPress(response.data)
      })
      .catch(err => alert(JSON.stringify(err?.response?.data)))
  }

  const handleSwicthPlateformView = (e, slug) => {
    const formData = new FormData();
    formData.append("displayed_on_platform_page", e);
    Api.patch(PRESS_URL + slug + "/", formData).then(res => {
      getPress()
    }).catch(err => {
      alert(JSON.stringify(err?.response?.data))
    });
  }

  const handleSwicthConsultingView = (e, slug) => {
    const formData = new FormData();
    formData.append("displayed_on_consulting_page", e);
    Api.patch(PRESS_URL + slug + "/", formData).then(res => {
      getPress()
    }).catch(err => {
      alert(JSON.stringify(err?.response?.data))
    });
  }

  const plateformCaseCount = () => {
    let count = 0;
    for (const obj of press) {
      if (obj.displayed_on_platform_page === true) count++;
    }
    return count
  }

  const consultingCaseCount = () => {
    let count = 0;
    for (const obj of press) {
      if (obj.displayed_on_consulting_page === true) count++;
    }
    return count
  }

  useEffect(() => {
    getPress();


  }, []);

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <div className="btn-toolbar mb-2 mb-md-0 d-flex align-items-center">
          <div className="btn-group mr-2">
            <Button variant="primary" onClick={() => handleShowAddModal()}>Ajouter un article</Button>
          </div>
          <div className="mx-5">Côté plateforme: {plateformCaseCount()}</div>
          <div >Côté cabinet: {consultingCaseCount()}</div>

        </div>
      </div>
      <Row className="mt-3">
        {press && press.map((c) => (
          <Col xs={4} className="mb-3" key={c.slug}>
            <Card >
              <Card.Img src={c?.logo?.file} style={{ width: '50%' }} />
              <Card.Body>
                <Card.Text>
                  <strong>Titre:</strong> {c.title}
                </Card.Text>
                <Card.Text>
                  <strong>Lien:</strong> {c.url}
                </Card.Text>
                <div className="d-flex ">
                  <DropdownButton variant="outline-warning" size="sm" className="rounded" title="Actions">
                    {c.logo ? <Dropdown.Item onClick={() => handleDeleteLogo(c.id)}>Supprimer le logo</Dropdown.Item> : <Dropdown.Item onClick={() => handleShowLogoModal(c.id)}>Ajouter un logo</Dropdown.Item>}

                    <Dropdown.Item onClick={() => handleShowModifyModal(c.id, c.title, c.url)}>Modifier</Dropdown.Item>
                  </DropdownButton>
                  <Button variant="outline-danger" size="sm" className="pitchichi ml-2" onClick={() => handleDeleteCase(c.id)}>Supprimer</Button>
                 
                </div>
                <Form className="mt-3">
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      label="Côté plateforme"
                      checked={c.displayed_on_platform_page}
                      onChange={(e) => handleSwicthPlateformView(e.target.checked, c.id)}

                    />
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      checked={c.displayed_on_consulting_page}
                      onChange={(e) => handleSwicthConsultingView(e.target.checked, c.id)}
                      label="Côté cabinet"
                    />
                  </Form>

              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      <Modal show={showLogoModal} onHide={handleCloseLogoModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Ajouter un logo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmitLogoModal}>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label" for="inputState">Fichier</label>
              <div className="col-sm-4">
                <input type="file" onChange={fileChangedHandler} />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label" for="inputState">Titre</label>
              <div className="col-sm-4">
                <input type="text" className="form-control" id="" onChange={(e) => setTitle(e.target.value)} value={title} />
              </div>
            </div>

          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseLogoModal}>
            Annuler
          </Button>
          <Button variant="primary" onClick={handleSubmitLogoModal}>
            Ajouter
          </Button>
        </Modal.Footer>
      </Modal>


      <Modal size="lg" show={showModifyModal} onHide={handleCloseModifyModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Modifier cet article</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmitModifyModal}>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label" for="inputState">Titre</label>
              <div className="col-sm-10">
                <input type="text" className="form-control" id="" onChange={(e) => setPressTitle(e.target.value)} value={pressTitle} />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label" for="inputState">URL</label>
              <div className="col-sm-10">
                <input type="text" className="form-control" id="" onChange={(e) => setPressUrl(e.target.value)} value={pressUrl} />
              </div>
            </div>

          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModifyModal}>
            Annuler
          </Button>
          <Button variant="primary" onClick={handleSubmitModifyModal}>
            Modifier
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal size="lg" show={showAddModal} onHide={handleCloseAddModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Ajouter un article</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmitAddModal}>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label" for="inputState">Titre</label>
              <div className="col-sm-10">
                <input type="text" className="form-control" id="" onChange={(e) => setPressTitle(e.target.value)} value={pressTitle} />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label" for="inputState">URL</label>
              <div className="col-sm-10">
                <input type="text" className="form-control" id="" onChange={(e) => setPressUrl(e.target.value)} value={pressUrl} />
              </div>
            </div>

          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseAddModal}>
            Annuler
          </Button>
          <Button variant="primary" onClick={handleSubmitAddModal}>
            Ajouter
          </Button>
        </Modal.Footer>
      </Modal>



    </>
  );
}

