import './../App.scss';
import React, { useState, useEffect } from "react";
import TabGeneral from './../Components/TabGeneral'
import TabKeyprofile from './../Components/TabKeyprofile'
import TabNewbiz from './../Components/TabNewbiz'
import TabReferencies from './../Components/TabReferencies'
import TabCategories from '../Components/TabCategories';
import Api from '../util/Api'
import { PATCH_AGENCY, AGENCIES_URL, CUSTOMER_CASES_URL } from '../util/Constant'
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import TabNotes from '../Components/TabNotes';
import TabMainClients from '../Components/TabMainClients';
import TabLinks from '../Components/TabLinks';

export default function Agency(props) {
    const [key, setKey] = useState('Général');
    const [loading, setLoading] = useState(false)
    const [agency, setAgency] = useState('');
    const [customerCases, setCustomerCases] = useState('')
    const [characteristics, setCharacteristics] = useState('')
    const [orderingClass, setOrderingClass] = useState('')
    const [internalNotes, setInternalNotes] = useState('')
    const [internalNotes1, setInternalNotes1] = useState('')
    const [internalNotes2, setInternalNotes2] = useState('')

    const [customers, setCustomers] = useState([])
    const [creationDirectors, setCreationDirectors] = useState([])
    const [newbizDirectors, setNewbizDirectors] = useState([])
    const [strategyDirectors, setStrategyDirectors] = useState([])
    const [productionDirectors, setProductionDirectors] = useState([])
    const [scores, setScores] = useState([])
    const [newbizEmail, setNewBizEmail] = useState('')
    const [newbizFirstname, setNewBizFirstname] = useState('')
    const [newbizName, setNewBizName] = useState('')
    const [newbizPhone, setNewBizPhone] = useState('')
    const [facebook, setfacebook] = useState('')
    const [insta, setinsta] = useState('')
    const [site, setsite] = useState('')
    const [twitter, settwitter] = useState('')
    const isPitchichi = JSON.parse(localStorage.getItem('isPitchichi'))
    const [success, setsuccess] = useState(false)

    const getAgency = () => {
        if (props.match.params.slug) {
            Api.get(AGENCIES_URL + props.match.params.slug + "/")
                .then((response) => {
                    console.log(response)
                    setAgency(response.data)
                    setCharacteristics(response.data.characteristics)
                    setOrderingClass(response.data.type)
                    setInternalNotes(response.data.internal_notes)
                    setInternalNotes1(response.data.internal_notes_1)
                    setInternalNotes2(response.data.internal_notes_2)
                    setCreationDirectors(response.data.creation_directors)
                    setNewbizDirectors(response.data.newbiz_directors)
                    setStrategyDirectors(response.data.strategy_directors)
                    setProductionDirectors(response.data.production_directors)
                    setNewBizEmail(response.data.newbiz_email)
                    setNewBizFirstname(response.data.newbiz_firstname)
                    setNewBizName(response.data.newbiz_name)
                    setNewBizPhone(response.data.newbiz_phone)
                    response.data.social_media.forEach(function (item, index) {
                        if (item.label === "Facebook") {
                            setfacebook(item.url)
                        } else if (item.label === "Twitter") {
                            settwitter(item.url)
                        } else if (item.label === "Site") {
                            setsite(item.url)
                        } else if (item.label === "Instagram") {
                            setinsta(item.url)
                        }
                    });
                    if (response?.data?.scores?.length > 0) {
                        setScores(response.data.scores)
                    } else {
                        setScores([
                            {
                                "grade": 1,
                                "label": "Stratégie"
                            },
                            {
                                "grade": 1,
                                "label": "Créativité"
                            },
                            {
                                "grade": 1,
                                "label": "Rédaction"
                            },
                            {
                                "grade": 1,
                                "label": "Direction Artistique"
                            },
                            {
                                "grade": 1,
                                "label": "Craft/Production"
                            },
                            {
                                "grade": 1,
                                "label": "Tarif"
                            }
                        ])
                    }
                    setLoading(true)
                })
                .catch(err => alert(JSON.stringify(err?.response?.data))
                )
        }
    }

    const getCustomerCases = () => {
        if (props.match.params.slug) {
            Api.get(CUSTOMER_CASES_URL(props.match.params.slug))
                .then((response) => {
                    setCustomerCases(response.data)
                })
                .catch(err => alert(JSON.stringify(err?.response?.data))
                )
        }
    }

    const getAgencyCustomers = () => {
        if (props.match.params.slug) {
            Api.get(AGENCIES_URL + props.match.params.slug + "/customers/")
                .then((response) => {
                    setCustomers(response.data)
                })
                .catch(err => alert(JSON.stringify(err?.response?.data))
                )
        }
    }

    const handleCharacteristics = (value, index1, index2) => {
        const updated = characteristics.slice()
        updated[index1].characteristics[index2].value = !value
        setCharacteristics(updated)
        Api.patch(PATCH_AGENCY(props.match.params.slug), { characteristics: characteristics }).then(res => {
        }).catch(err => {
            alert(JSON.stringify(err?.response?.data))
        });
    }

    const handleOrderingClass = (value) => {
        const formData = new FormData();
        formData.append("type", value);
        setOrderingClass(value)
        Api.patch(PATCH_AGENCY(props.match.params.slug), formData).then(res => {

        }).catch(err => {
            alert(JSON.stringify(err?.response?.data))

        });
    }

    const handleNotesValuePatch = () => {
        const formData = new FormData();
        formData.append("internal_notes", internalNotes);
        formData.append("internal_notes_1", internalNotes1);
        formData.append("internal_notes_2", internalNotes2);
        Api.patch(PATCH_AGENCY(props.match.params.slug), formData).then(res => {
            setsuccess(true)
            setTimeout(() => {
                setsuccess(false)
            }, 1500);
        }).catch(err => {
            alert(JSON.stringify(err?.response?.data))
        });
        Api.patch(PATCH_AGENCY(props.match.params.slug), { "scores": scores }).then(res => {
            setsuccess(true)
            setTimeout(() => {
                setsuccess(false)
            }, 1500);
        }).catch(err => {
            alert(JSON.stringify(err?.response?.data))
        });
    }

    const handleScoresSwitch = (e, index) => {
        var integer = parseInt(e);
        const updated = scores.slice()
        updated[index].grade = integer
        setScores(updated)
    }

    const handleChangeCreationDirectors = (e) => {
        if (e) {
            var array = e.split(',');
            setCreationDirectors(array)

        } else {
            array = []
            setCreationDirectors(array)
        }


    }

    const handleChangeProductionDirectors = (e) => {
        if (e) {
            var array = e.split(',');
            setProductionDirectors(array)

        } else {
            array = []
            setProductionDirectors(array)
        }


    }

    const handleChangeNewbizDirectors = (e) => {
        if (e) {
            var array = e.split(',');
            setNewbizDirectors(array)

        } else {
            array = []
            setNewbizDirectors(array)
        }

    }

    const handleChangeStrategyDirectors = (e) => {
        if (e) {
            var array = e.split(',');
            setStrategyDirectors(array)

        } else {
            array = []
            setStrategyDirectors(array)
        }

    }

    const handleChangeNewbizEmail = (e) => {
        setNewBizEmail(e)
    }
    const handleChangeNewbizPhone = (e) => {
        setNewBizPhone(e)
    }
    const handleChangeNewbizFirstname = (e) => {
        setNewBizFirstname(e)
    }
    const handleChangeNewbizName = (e) => {
        setNewBizName(e)
    }
    const handleNewBizValuePatch = () => {
        const formData = new FormData();
        formData.append("newbiz_email", newbizEmail);
        formData.append("newbiz_phone", newbizPhone);
        formData.append("newbiz_name", newbizName);
        formData.append("newbiz_firstname", newbizFirstname);

        Api.patch(PATCH_AGENCY(props.match.params.slug), formData).then(res => {
            setsuccess(true)
            setTimeout(() => {
                setsuccess(false)
            }, 1500);
        }).catch(err => {
            alert(JSON.stringify(err?.response?.data))
        })
    }

    const handleKeyProfileValuePatch = () => {
        const formData = new FormData();
        formData.append("creation_directors", creationDirectors);
        formData.append("production_directors", productionDirectors);
        formData.append("newbiz_directors", newbizDirectors);
        formData.append("strategy_directors", strategyDirectors);
        Api.patch(PATCH_AGENCY(props.match.params.slug), formData).then(res => {
            setsuccess(true)
            setTimeout(() => {
                setsuccess(false)
            }, 1500);
        }).catch(err => {
            alert(JSON.stringify(err?.response?.data))
        });
    }

    const handleChangeFacebook = (e) => {
        setfacebook(e)
    }
    const handleChangeInstagram = (e) => {
        setinsta(e)
    }
    const handleChangeSite = (e) => {
        setsite(e)
    }
    const handleChangeTwitter = (e) => {
        settwitter(e)
    }

    const handleSocialValuePatch = () => {
        const params = {
            "social_media": [
                {
                    "label": "Facebook",
                    "url": facebook
                },
                {
                    "label": "Instagram",
                    "url": insta
                },
                {
                    "label": "Site",
                    "url": site
                },
                {
                    "label": "Twitter",
                    "url": twitter
                }
            ]
        }
        Api.patch(PATCH_AGENCY(props.match.params.slug), params).then(res => {
            setsuccess(true)
            setTimeout(() => {
                setsuccess(false)
            }, 1500);
        }).catch(err => {
            alert(JSON.stringify(err?.response?.data))
        });
    }

    useEffect(() => {
        getAgency();
        getCustomerCases()
        getAgencyCustomers()
    }, []);
    return (
        <>
            <div className="pt-3 pb-2 mb-3 border-bottom">
                <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="mb-3"
                >
                    <Tab eventKey="Général" title="Général">
                        <TabGeneral agency={agency} onClick={getAgency} />
                    </Tab>
                    <Tab eventKey="Personnes-clés" title="Personnes-clés">
                        <TabKeyprofile
                            creationDirectors={creationDirectors}
                            onChangeCreationDirectors={handleChangeCreationDirectors}
                            newbizDirectors={newbizDirectors}
                            onChangeNewbizDirectors={handleChangeNewbizDirectors}
                            strategyDirectors={strategyDirectors}
                            onChangeStrategyDirectors={handleChangeStrategyDirectors}
                            productionDirectors={productionDirectors}
                            onChangeProductionDirectors={handleChangeProductionDirectors}
                            onClick={handleKeyProfileValuePatch}
                            success={success}
                        />
                    </Tab>
                    <Tab eventKey="Newbiz" title="Newbiz">
                        <TabNewbiz
                            newbizEmail={newbizEmail}
                            newbizFirstname={newbizFirstname}
                            newbizName={newbizName}
                            newbizPhone={newbizPhone}
                            onChangeNewbizPhone={handleChangeNewbizPhone}
                            onChangeNewbizFirstname={handleChangeNewbizFirstname}
                            onChangeNewbizName={handleChangeNewbizName}
                            onChangeNewbizEmail={handleChangeNewbizEmail}
                            onClick={handleNewBizValuePatch}
                            success={success}
                        />
                    </Tab>
                    <Tab eventKey="Références Clients" title="Références">
                        <TabReferencies agency={agency} customerCases={customerCases} onClick={getCustomerCases} />
                    </Tab>
                    {isPitchichi && <Tab eventKey="Catégories" title="Catégories">
                        <TabCategories agency={agency} characteristics={characteristics} orderingClass={orderingClass} onClick2={handleCharacteristics} onClick3={handleOrderingClass} />
                    </Tab>}
                    {isPitchichi && <Tab eventKey="Notes Pitchichi" title="Notes Pitchichi">
                        <TabNotes 
                            internalNotes={internalNotes}
                            internalNotes1={internalNotes1} 
                            internalNotes2={internalNotes2}
                            onChange={(e) => setInternalNotes(e)}
                            onChange1={(e) => setInternalNotes1(e)}
                            onChange2={(e) => setInternalNotes2(e)}
                            onClick4={handleNotesValuePatch}
                            onSwitchScores={handleScoresSwitch} scores={scores} success={success} />
                    </Tab>}
                    <Tab eventKey="Portfolio" title="Principaux Clients">
                        <TabMainClients customers={customers} agency={agency} onClick={getAgencyCustomers} />
                    </Tab>
                    <Tab eventKey="Lien" title="Lien">
                        <TabLinks
                            facebook={facebook}
                            insta={insta}
                            site={site}
                            twitter={twitter}
                            onChangeFacebook={handleChangeFacebook}
                            onChangeInstagram={handleChangeInstagram}
                            onChangeSite={handleChangeSite}
                            onChangeTwitter={handleChangeTwitter}
                            onClick={handleSocialValuePatch}
                            success={success}
                        />
                    </Tab>
                </Tabs>
            </div>
        </>
    );
}

