import React, { useState, useEffect } from "react";
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';

import { EMAIL_VERIFICATION_URL } from '../util/Constant'

import './ConfirmedMail.scss';
import logo from '../Assets/logo-main.svg'
export default function ConfirmedMail(props) {
    const [verified, setverified] = useState(false)
    const [loading, setloading] = useState(true)


    const handleEmailConfirmation = () => {
        axios.post(EMAIL_VERIFICATION_URL, { "key": props.match.params.slug }).then(res => {
            setverified(true)
            setloading(false)
        }).catch(err => {
            setloading(false)
        });
    }

    useEffect(() => {
        handleEmailConfirmation();
    }, []);
    return (
        <>

            {loading && <Spinner animation="grow" variant="primary" role="status" className="spinner-emailconfirmation">
                <span className="visually-hidden">Loading...</span>
            </Spinner>}

            {(verified && !loading) && <div className="wrapper text-center">
                <form className="form-signin" >
                    <img className="mb-4" src={logo} alt="" width="250" />
                    <div>Votre email a bien été confirmé.</div>
                    <div className="text-primary mt-4">
                        <a href="/signin" className="btn btn-lg btn-primary btn-block">Se connecter</a>
                    </div>
                    <p className="mt-5 mb-3 text-muted">&copy; PITCHICHI 2021</p>
                </form>
            </div> }

            {(!verified && !loading) && <div className="wrapper text-center">
                <form className="form-signin" >
                    <img className="mb-4" src={logo} alt="" width="250" />
                    <div>Une erreur est survenue.</div>
                    <div className="text-primary mt-4">
                        <a href="/signin" className="btn btn-lg btn-primary btn-block">Retour</a>
                    </div>
                    <p className="mt-5 mb-3 text-muted">&copy; PITCHICHI 2021</p>
                </form>
            </div> }

        </>
    );

}





