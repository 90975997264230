
import './../App.scss';
import React, { useState, useEffect } from "react";
import { LinkContainer } from 'react-router-bootstrap'
import './Users.scss';

import Button from 'react-bootstrap/Button';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import hiddenPicto from "./../Assets/crosseye.svg"
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import ToggleButton from "react-bootstrap/ToggleButton";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Modal from 'react-bootstrap/Modal';
import Api from '../util/Api'
import { USERS_URL, AGENCIES_URL, GET_USER } from '../util/Constant'

export default function Users() {
    const [users, setUsers] = useState([])
    const [userId, setUserId] = useState()
    const [agencies, setAgencies] = useState([])
    const [radioValue, setRadioValue] = useState('');
    const [associatedAgency, setAssociatedAgency] = useState([])


    const [showAddAgencyModal, setShowAddAgencyModal] = useState(false)
    const [searchName, setSearchName] = useState('')

    const handleCloseAddAgencyModal = () => {
        setShowAddAgencyModal(false)
    }

    const handleShowAddAgencyModal = (userid) => {
        setUserId(userid)
        setShowAddAgencyModal(true)
        getUser(userid)
        setRadioValue('')
    }

    const handleSubmitAddAgencyModal = () => {
        
        const json = {
            "is_pitchichi": false,
            "agencies": associatedAgency
        }
        Api.patch(USERS_URL + userId + "/", json).then(res => {
            getUsers();
            handleCloseAddAgencyModal()

        }).catch(err => {
            alert(JSON.stringify(err?.response?.data))
        });
        

    }

    const getAgencies = () => {
        Api.get(AGENCIES_URL, { params: { name: searchName } })
            .then((response) => {
                setAgencies(response.data)
            }).catch((err) => {
                alert(JSON.stringify(err?.response?.data))
            }).then(() => {

            })
    }

    const getUsers = () => {
        Api.get(USERS_URL)
            .then((response) => {
                setUsers(response.data)
            }).catch((err) => {
                alert(JSON.stringify(err?.response?.data))
            }).then(() => {

            })
    }
    const getUser = (id) => {
        Api.get(GET_USER(id))
            .then((response) => {
                setAssociatedAgency(response.data.agencies)
            }).catch((err) => {
                alert(JSON.stringify(err?.response?.data))
            }).then(() => {

            })
    }

    const handleRemove = () => {
        associatedAgency.forEach(element => {
            delete element.name
            delete element.hidden
            if(element.slug === radioValue) {
                associatedAgency.splice(element, 1)
            }
        });
       
        console.log(associatedAgency)
        setRadioValue('')
    }

    const handleAdd = () => {
        associatedAgency.forEach(element => {
            delete element.name
        });
        associatedAgency.push({"slug": radioValue})
        console.log(associatedAgency)
        setRadioValue('')
    }

    useEffect(() => {
        getUsers();
        getAgencies();
    }, [searchName]);
    return (
        <>
            <div className="mt-3">
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Nom d'utilisateur</th>
                            <th>Email</th>
                            <th>Agence(s)</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users && users.map((user) => (
                            <tr>
                                <td>{user.id}</td>
                                <td>{user.username}</td>
                                <td>{user.email}</td>
                                <td>
                                    {user?.agencies.map((agency) => (
                                        <div className="d-flex flex-column justify-content-center">
                                            <span>{agency?.name}</span>
                                        </div>
                                    ))}

                                </td>
                                <td>
                                    <Button onClick={() => handleShowAddAgencyModal(user.id)} variant="outline-primary" size="sm" className="pitchichi"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-link-45deg" viewBox="0 0 16 16">
                                        <path d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1.002 1.002 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4.018 4.018 0 0 1-.128-1.287z" />
                                        <path d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243L6.586 4.672z" />
                                    </svg></Button>


                                </td>
                            </tr>
                        ))}

                    </tbody>
                </Table>
            </div>

            <Modal size="xl" show={showAddAgencyModal} onHide={handleCloseAddAgencyModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Associer à une agence</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                        {associatedAgency?.map((agency, idx) => (
                                <ToggleButton
                                    className="m-1"
                                    key={idx}
                                    id={`agency-${idx}`}
                                    type="radio"
                                    variant="outline-primary"
                                    name="associated"
                                    value={agency.slug}
                                    checked={radioValue === agency.slug}
                                    onChange={(e) => setRadioValue(e.currentTarget.value)}
                                >
                                    {agency.slug}
                                </ToggleButton>
                            ))}
                        </Col>
                        <Col xs={1} className="d-flex flex-column justify-content-center">
                            <Button
                                variant="outline-primary"
                                onClick={handleRemove}
                                className="mb-1"
                                size="sm"
                            >
                                {">"}
                            </Button>
                            <Button
                                variant="outline-primary"
                                onClick={handleAdd}
                                className="mb-1"
                                size="sm"
                            >
                                {"<"}
                            </Button>
                        </Col>
                        <Col><Form.Group className="mb-3" controlId="formAgenciesName">
                            <Form.Control type="text" onChange={(e) => setSearchName(e.target.value)} value={searchName} autoComplete="off" placeholder="Rechercher par nom" />
                        </Form.Group>
                            {agencies?.results?.map((agency, idx) => (
                                <ToggleButton
                                    className="m-1"
                                    key={idx}
                                    id={`agency-${idx}`}
                                    type="radio"
                                    variant="outline-primary"
                                    name="agency"
                                    value={agency.slug}
                                    checked={radioValue === agency.slug}
                                    onChange={(e) => setRadioValue(e.currentTarget.value)}
                                >
                                    {agency.name}
                                </ToggleButton>
                            ))}
                        </Col>
                    </Row>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseAddAgencyModal}>
                        Annuler
                    </Button>
                    <Button variant="primary" onClick={handleSubmitAddAgencyModal}>
                        Associer
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    );
}

