import React from "react";
import { NavLink } from 'react-router-dom';

export default function SideBar() {

const isPitchichi = JSON.parse(localStorage.getItem('isPitchichi'))
    
        return (
            <div className="container-fluid">
                <div className="row">
                    <nav id="sidebarMenu" className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
                        <div className="sidebar-sticky pt-3">
                            <ul className="nav nav-pills flex-column">
                                <li className="nav-item">
                                    <NavLink to="/agencies" className="nav-link" activeClassName="active">
                                        Liste des agences
                                    </NavLink>
                                </li>
                                {isPitchichi && <><li className="nav-item">
                                    <NavLink to="/cases" className="nav-link" activeClassName="active">
                                        Cas client
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/press" className="nav-link" activeClassName="active">
                                        Articles de presse
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/customerlogo" className="nav-link" activeClassName="active">
                                        Logos clients
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/testimonies" className="nav-link" activeClassName="active">
                                        Testimoniaux
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/users" className="nav-link" activeClassName="active">
                                        Utilisateurs
                                    </NavLink>
                                </li> </>}


                            </ul>
                        </div>
                    </nav>


                </div>
            </div>
        );
    }
