import React, { Component } from "react";
import axios from 'axios';

import { API_URL } from "../util/Constant";
import './SignUp.scss';
import logo from '../Assets/logo-main.svg'

export default class SignUp extends Component {

    constructor() {
        super();
        this.state = {
            email: "",
            password1: "",
            password2: "",

            errorMessageEmail: undefined,
            errorMessagePassword: undefined,
            completed: false,

            isLogging: false
        };
    }

    handleChangeText(e, a) {
        this.setState({
            [e]: a.target.value
        });
        this.setState({
            errorMessageEmail: undefined,
            errorMessagePassword: undefined
        });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({
            isLogging: true,
            errorMessageEmail: undefined,
            errorMessagePassword: undefined

        });

        const user = {
            email: this.state.email,
            password1: this.state.password1,
            password2: this.state.password2,

        }

        axios.post(API_URL + "/auth/registration/", user).then(res => {

            this.setState({ completed: true })

        }).catch((err) => {
            alert(JSON.stringify(err?.response?.data))

        });
    }


    render() {
        return (
            <>
                {!this.state.completed && <div className="wrapper text-center">
                    <form class="form-signin" onSubmit={this.handleSubmit.bind(this)} method="POST">
                        <img class="mb-4" src={logo} alt="" width="250" />
                        <input autoComplete="off" id="email" className="form-control w-100 mb-3" type="text" onChange={this.handleChangeText.bind(this, "email")} placeholder="Votre Email" required />
                        <input autoComplete="off" id="pwd1" className="form-control mb-3" type="password" onChange={this.handleChangeText.bind(this, "password1")} placeholder="Choisissez un mot de passe" required />
                        <input autoComplete="off" id="pwd2" className="form-control mb-3" type="password" onChange={this.handleChangeText.bind(this, "password2")} placeholder="Répétez votre mot de passe" required />

                        <button class="btn btn-lg btn-primary btn-block" type="submit">S'enregistrer</button>
                        <div className="text-primary mt-4">
                            <a href="/signin" class="btn btn-lg btn-primary btn-block">Annuler</a>
                        </div>
                        {this.state.errorMessageEmail && <div className="text-danger mt-2"><small >{this.state.errorMessageEmail}</small></div>}
                        {this.state.errorMessagePassword && <div className="text-danger mt-2"><small >{this.state.errorMessagePassword}</small></div>}
                        <p class="mt-5 mb-3 text-muted">&copy; PITCHICHI 2021</p>
                    </form>
                </div>}

                {this.state.completed && <div className="wrapper text-center">
                    <form class="form-signin" onSubmit={this.handleSubmit.bind(this)} method="POST">
                        <img class="mb-4" src={logo} alt="" width="250" />
                        <div>Un email vient de vous être envoyé afin de valider et activer votre compte.</div>
                        <div className="text-primary mt-4">
                            <a href="/signin" class="btn btn-lg btn-primary btn-block">Retour</a>
                        </div>
                        <p class="mt-5 mb-3 text-muted">&copy; PITCHICHI 2021</p>
                    </form>
                </div>}

                {/* <form className="w-100" onSubmit={this.handleSubmit.bind(this)} method="POST">
                            <div className="form-group">
                                <label htmlFor="email" className="font-weight-bold">Nom d'utilisateur</label>
                                <input autoComplete="off" id="email" className="form-control w-100" type="text" onChange={this.handleChangeText.bind(this, "userName")} placeholder="Nom d'utilisateur" required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="pwd" className="font-weight-bold">Mot de passe</label>
                                <input autoComplete="off" id="pwd" className="form-control" type="password" onChange={this.handleChangeText.bind(this, "password")} placeholder="Mot de passe" required />
                            </div>
                            <button type="submit" disabled={!this.state.userName || !this.state.password} className="btn btn-info" onClick={this.handleSubmit.bind(this)}>Connexion</button>
                            {this.state.errorMessage && <div><small className="text-danger mt-2">Nom d'utilisateur ou mot de passe incorrect</small></div>}

                        </form> */}


                {/* <ApiVersion /> */}
            </>
        );
    }
}