import './../App.scss';
import axios from 'axios'
import React, { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import ContentEditable from "react-contenteditable";
import sanitizeHtml from "sanitize-html";
import Modal from 'react-bootstrap/Modal';
import Api from '../util/Api'
import { TESTIMONIALS_URL, PRESS_LOGO_URL } from '../util/Constant'

export default function Testimonies() {

  const [testimonials, setTestimonials] = useState([])
  const [testimonialId, setTestimonialId] = useState('')
  const [showLogoModal, setShowLogoModal] = useState(false)
  const [showModifyModal, setShowModifyModal] = useState(false)
  const [showAddModal, setShowAddModal] = useState(false)

  const [selectedFile, setSelectedFile] = useState("");
  const [title, setTitle] = useState("");
  const [author, setAuthor] = useState("");
  const [quote, setQuote] = useState("");

  const [html, sethtml] = useState("")


  const sanitizeConf = {
    allowedTags: ["b", "i", "em", "strong", "a", "p", "h1"],
  };

  const handleChange = evt => {
    sethtml(evt.target.value);
    console.log(html)
  };

  const handleCloseModifyModal = () => {
    setShowModifyModal(false)
  }

  const handleSubmitModifyModal = event => {
    const formData = new FormData();
    formData.append("author", author);
    formData.append("quote", html);
    event.preventDefault();
    Api.patch(TESTIMONIALS_URL + (testimonialId) + '/', formData).then(res => {
      setShowModifyModal(false)
      getTestimonials()
    }).catch(err => {
      alert(JSON.stringify(err?.response?.data))
    });
  }

  const handleShowModifyModal = (c) => {
    setAuthor(c.author)
    sethtml(c.quote)
    setTestimonialId(c.id)
    setShowModifyModal(true)

  }

  const handleCloseAddModal = () => {
    setShowAddModal(false)
  }

  const handleSubmitAddModal = event => {
    const formData = new FormData();
    formData.append("author", author);
    formData.append("quote", html);
    event.preventDefault();
    Api.post(TESTIMONIALS_URL, formData).then(res => {
      setShowAddModal(false)
      getTestimonials()
    }).catch(err => {
      alert(JSON.stringify(err?.response?.data))
    });
  }

  const handleShowAddModal = () => {
    setAuthor("")
    setQuote("")
    sethtml("")
    setTestimonialId("")
    setShowAddModal(true)
  }


  const handleDeleteCase = (id) => {
    Api.delete(TESTIMONIALS_URL + (id) + '/').then(res => {
      getTestimonials()
    }).catch(err => {
      alert(JSON.stringify(err?.response?.data))
    });
  }

  const getTestimonials = () => {
    Api.get(TESTIMONIALS_URL)
      .then((response) => {
        setTestimonials(response.data)
      })
      .catch(err => alert(JSON.stringify(err?.response?.data)))
  }

  const handleSwicthPlateformView = (e, slug) => {
    const formData = new FormData();
    formData.append("displayed_on_platform_page", e);
    Api.patch(TESTIMONIALS_URL + slug + "/", formData).then(res => {
      getTestimonials()
    }).catch(err => {
      alert(JSON.stringify(err?.response?.data))
    });
  }

  const handleSwicthConsultingView = (e, slug) => {
    const formData = new FormData();
    formData.append("displayed_on_consulting_page", e);
    Api.patch(TESTIMONIALS_URL + slug + "/", formData).then(res => {
      getTestimonials()
    }).catch(err => {
      alert(JSON.stringify(err?.response?.data))
    });
  }

  const plateformCaseCount = () => {
    let count = 0;
    for (const obj of testimonials) {
      if (obj.displayed_on_platform_page === true) count++;
    }
    return count
  }

  const consultingCaseCount = () => {
    let count = 0;
    for (const obj of testimonials) {
      if (obj.displayed_on_consulting_page === true) count++;
    }
    return count
  }

  function EditButton(props) {
    return (
      <Button
      className="mr-2 mb-4"
        key={props.cmd}
        onMouseDown={evt => {
          evt.preventDefault(); // Avoids loosing focus from the editable area
          document.execCommand(props.cmd, false, props.arg); // Send the command to the browser
        }}
      >
        {props.name || props.cmd}
      </Button>
    );
  }

  useEffect(() => {
    getTestimonials();


  }, []);

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <div className="btn-toolbar mb-2 mb-md-0 d-flex align-items-center">
          <div className="btn-group mr-2">
            <Button variant="primary" onClick={() => handleShowAddModal()}>Ajouter un témoignage</Button>
          </div>
          <div className="mx-5">Côté plateforme: {plateformCaseCount()}</div>
          <div >Côté cabinet: {consultingCaseCount()}</div>

        </div>
      </div>
      <Row className="mt-3">
        {testimonials && testimonials.map((c) => (
          <Col xs={4} className="mb-3" key={c.slug}>
            <Card >
              <Card.Img src={c?.logo?.file} style={{ width: '50%' }} />
              <Card.Body>
                <Card.Text>
                  <strong>Texte: </strong> <span dangerouslySetInnerHTML={{__html: `${c.quote}`}}></span>
                </Card.Text>
                <Card.Text>
                  <strong>Auteur: </strong> {c.author}
                </Card.Text>
                <div className="d-flex ">
                  {/* <DropdownButton variant="outline-warning" size="sm" className="rounded" title="Actions">

                    <Dropdown.Item onClick={() => handleShowModifyModal(c.id, c.quote, c.author)}>Modifier</Dropdown.Item>
                  </DropdownButton> */}
                  <Button variant="outline-primary" size="sm" className="pitchichi" onClick={() => handleShowModifyModal(c)}>Modifier témoignage</Button>
                  <Button variant="outline-danger" size="sm" className="pitchichi ml-2" onClick={() => handleDeleteCase(c.id)}>Supprimer</Button>

                </div>
                <Form className="mt-3">
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    label="Côté plateforme"
                    checked={c.displayed_on_platform_page}
                    onChange={(e) => handleSwicthPlateformView(e.target.checked, c.id)}

                  />
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    checked={c.displayed_on_consulting_page}
                    onChange={(e) => handleSwicthConsultingView(e.target.checked, c.id)}
                    label="Côté cabinet"
                  />
                </Form>

              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>

      <Modal size="lg" show={showModifyModal} onHide={handleCloseModifyModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Modifier cet article</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmitModifyModal}>
          <div className="form-group row">
              <label className="col-sm-2 col-form-label" for="inputState">Texte</label>
              <div className="col-sm-10">
                <ContentEditable
                  className="editable"
                  tagName="pre"
                  html={html}
                  onChange={handleChange} // handle innerHTML change
                />
              </div>
            </div>
            <EditButton cmd="italic" />
            <EditButton cmd="bold" />

            <div className="form-group row">
              <label className="col-sm-2 col-form-label" for="inputState">Auteur</label>
              <div className="col-sm-10">
                <input type="text" className="form-control" id="" onChange={(e) => setAuthor(e.target.value)} value={author} />
              </div>
            </div>

          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModifyModal}>
            Annuler
          </Button>
          <Button variant="primary" onClick={handleSubmitModifyModal}>
            Modifier
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal size="lg" show={showAddModal} onHide={handleCloseAddModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Ajouter un témoignage</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmitAddModal}>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label" for="inputState">Texte</label>
              <div className="col-sm-10">
                <ContentEditable
                  className="editable"
                  tagName="pre"
                  html={html}
                  onChange={handleChange} // handle innerHTML change
                />
              </div>
            </div>
            <EditButton cmd="italic" />
            <EditButton cmd="bold" />

            <div className="form-group row">
              <label className="col-sm-2 col-form-label" for="inputState">Auteur</label>
              <div className="col-sm-10">
                <input type="text" className="form-control" id="" onChange={(e) => setAuthor(e.target.value)} value={author} />
              </div>
            </div>
          </form>


        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseAddModal}>
            Annuler
          </Button>
          <Button variant="primary" onClick={handleSubmitAddModal}>
            Ajouter
          </Button>
        </Modal.Footer>
      </Modal>



    </>
  );
}

