import './../App.scss';
import React, { useState, useEffect } from "react";
import { LinkContainer } from 'react-router-bootstrap'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';


import Modal from 'react-bootstrap/Modal';
import Api from '../util/Api'
import { PITCHICHI_CASES_URL, PITCHICHI_CASE_URL } from '../util/Constant'

export default function Customers() {
  const [cases, setCases] = useState([])
  const [oneCase, setOneCase] = useState({})
  const [oneCaseCustomer, setOneCaseCustomer] = useState("")
  const [oneCaseName, setOneCaseName] = useState("")
  const [video1, setVideo1] = useState("")
  const [video2, setVideo2] = useState("")

  const [showModifyModal, setShowModifyModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);

  const getCustomerCases = () => {
    Api.get(PITCHICHI_CASES_URL)
      .then((response) => {
        setCases(response.data)
      }).catch(err => {
        alert(JSON.stringify(err?.response?.data))
      });
  }

  const handleCloseModifyModal = (slug) => {
    setShowModifyModal(false)
  };

  const handleCloseAddModal = (slug) => {
    setShowAddModal(false)
  };

  const handleSubmitModifyModal = () => {
    const formData = new FormData();
    const videosArrays = []
    if (video1) {
      videosArrays.push(video1)
    }
    if (video2) {
      videosArrays.push(video2)
    }
    formData.append("customer", oneCaseCustomer);
    formData.append("name", oneCaseName);
    if (videosArrays.length > 0) {
      formData.append("video_urls", JSON.stringify(videosArrays))
    } else {
      formData.append("video_urls", null)
    }

    Api.patch(PITCHICHI_CASE_URL(oneCase.slug), formData).then(res => {
      getCustomerCases()
      setShowModifyModal(false)

    }).catch(err => {
      alert(JSON.stringify(err?.response?.data))

    });
  }

  const handleSubmitAddModal = () => {
    const formData = new FormData();
    formData.append("customer", oneCaseCustomer);
    formData.append("name", oneCaseName);
    Api.post(PITCHICHI_CASES_URL, formData).then(res => {
      getCustomerCases()
      setShowAddModal(false)

    }).catch(err => {
      alert(JSON.stringify(err?.response?.data))

    });
  }

  const handleShowAddModal = () => {
    setOneCaseCustomer("")
    setOneCaseName("")
    setShowAddModal(true)
  }



  const handleDeleteCase = (slug) => {
    Api.delete(PITCHICHI_CASE_URL(slug)).then(res => {
      getCustomerCases()

    }).catch(err => {
      alert(JSON.stringify(err?.response?.data))
    });
  }

  const handleSwicthPlateformView = (e, slug) => {
    const formData = new FormData();
    formData.append("displayed_on_platform_page", e);
    Api.patch(PITCHICHI_CASES_URL + slug + "/", formData).then(res => {
      getCustomerCases()
    }).catch(err => {
      alert(JSON.stringify(err?.response?.data))
    });
  }

  const handleSwicthConsultingView = (e, slug) => {
    const formData = new FormData();
    formData.append("displayed_on_consulting_page", e);
    Api.patch(PITCHICHI_CASES_URL + slug + "/", formData).then(res => {
      getCustomerCases()
    }).catch(err => {
      alert(JSON.stringify(err?.response?.data))
    });
  }

  const plateformCaseCount = () => {
    let count = 0;
    for (const obj of cases) {
      if (obj.displayed_on_platform_page === true) count++;
    }
    return count
  }

  const consultingCaseCount = () => {
    let count = 0;
    for (const obj of cases) {
      if (obj.displayed_on_consulting_page === true) count++;
    }
    return count
  }

  useEffect(() => {
    getCustomerCases();
  }, []);

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <div className="btn-toolbar mb-2 mb-md-0 d-flex align-items-center">
          <div className="btn-group mr-2">
            <Button variant="primary" onClick={handleShowAddModal} >Ajouter un cas client</Button>

          </div>
          <div className="mx-5">Côté plateforme: {plateformCaseCount()}</div>
          <div >Côté cabinet: {consultingCaseCount()}</div>
        </div>
      </div>
      <Row className="mt-3">
        {cases && cases.map((c) => (
          <Col xs={3} className="mb-3" key={c.slug}>
            <Card >
              <Card.Img src={c?.thumbnail?.file} />
              <Card.Body>
                <Card.Text>
                  <strong>Client:</strong> {c.customer}
                </Card.Text>
                <Card.Text>
                  <strong>Nom de la campagne:</strong> {c.name}
                </Card.Text>
                <div>
                  <LinkContainer to={`/case/${c.slug}`}>
                    <Button variant="outline-primary" size="sm" className="pitchichi" >Modifier</Button>
                  </LinkContainer>
                  <Button variant="outline-danger" size="sm" className="pitchichi ml-2" onClick={() => handleDeleteCase(c.slug)}>Supprimer</Button>

                  <Form className="mt-3">
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      label="Côté plateforme"
                      checked={c.displayed_on_platform_page}
                      onChange={(e) => handleSwicthPlateformView(e.target.checked, c.slug)}

                    />
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      checked={c.displayed_on_consulting_page}
                      onChange={(e) => handleSwicthConsultingView(e.target.checked, c.slug)}
                      label="Côté cabinet"
                    />
                  </Form>
                </div>


              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      <Modal show={showModifyModal} size="lg" onHide={handleCloseModifyModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Modifier le cas client</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmitModifyModal}>
            <div className="form-group row">
              <label className="col-3 col-form-label" for="inputState">Client</label>
              <div className="col-8">
                <input type="text" className="form-control" id="" onChange={(e) => setOneCaseCustomer(e.target.value)} value={oneCaseCustomer} />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-3 col-form-label" for="inputState">Nom de la campagne</label>
              <div className="col-8">
                <input type="text" className="form-control" id="" onChange={(e) => setOneCaseName(e.target.value)} value={oneCaseName} />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-3 col-form-label" htmlFor="description">Description *</label>
              <div className="col-8">
                <textarea className="form-control" id="exampleFormControlTextarea1" name="description" onChange={(e) => setOneCaseName(e.target.value)} maxLength="340" rows="5" value={oneCaseName}></textarea>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-3 col-form-label" htmlFor="description">Video 1</label>
              <div className="col-8">
                <input type="text" className="form-control" id="" onChange={(e) => setVideo1(e.target.value)} value={video1} />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-3 col-form-label" htmlFor="description">Video 2</label>
              <div className="col-8">
                <input type="text" className="form-control" id="" onChange={(e) => setVideo2(e.target.value)} value={video2} />
              </div>
            </div>




          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModifyModal}>
            Annuler
          </Button>
          <Button variant="primary" onClick={handleSubmitModifyModal}>
            Modifier
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showAddModal} size="lg" onHide={handleCloseAddModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Ajouter un cas client</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmitAddModal}>
            <div className="form-group row">
              <label className="col-3 col-form-label" for="inputState">Client</label>
              <div className="col-8">
                <input type="text" className="form-control" id="" onChange={(e) => setOneCaseCustomer(e.target.value)} value={oneCaseCustomer} />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-3 col-form-label" for="inputState">Nom de la campagne</label>
              <div className="col-8">
                <input type="text" className="form-control" id="" onChange={(e) => setOneCaseName(e.target.value)} value={oneCaseName} />
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseAddModal}>
            Annuler
          </Button>
          <Button variant="primary" onClick={handleSubmitAddModal}>
            Ajouter
          </Button>
        </Modal.Footer>
      </Modal>



    </>
  );
}

