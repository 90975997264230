import React from "react";
import { Route, Redirect } from 'react-router-dom';
import { isTokenExpired } from "./TokenUtil";



export default function ProtectedRoute({component: Component, ...rest }) {
    
    let refreshToken = localStorage.getItem('refreshToken');
    let authenticated = (refreshToken != null && !isTokenExpired(refreshToken));

    return <Route {...rest} render={ props =>
      authenticated ? (
        <Component {...rest} {...props} />
      ) : (
        <Redirect to="/signin"/>
      )
    } />
}