import './../App.scss';
import React, { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import Api from '../util/Api'
import { AGENCIES_URL, CUSTOMER_AVAILABLE_URL } from '../util/Constant'

export default function TabMainClients(props) {
    const [showAddModal, setShowAddModal] = useState(false)
    const [showModifyModal, setShowModifyModal] = useState(false)

    const [customer, setCustomer] = useState('')
    const [customerSlug, setCustomerSlug] = useState('')
    const [text, setText] = useState('')
    const [customers, setCustomers] = useState([])
    const [suggestions, setSuggestions] = useState()
    const handleCloseAddModal = () => {
        setShowAddModal(false)
    }
    const handleCloseModifyModal = () => {
        setShowModifyModal(false)

    }

    const loadCustomers = () => {
        Api.get(CUSTOMER_AVAILABLE_URL)
            .then((response) => {
                setCustomers(response.data)

            }).catch((err) => {
                alert(JSON.stringify(err?.response?.data))
            }).then(() => {

            })
    }
    const onChangeHandler = (text) => {
        let matches = []
        if (text.length > 1) {
            matches = customers.filter(customer => {
                const regex = new RegExp(`${text}`, "gi");
                return customer.name.match(regex)
            })
        }
        setSuggestions(matches)
        setText(text)
    }

    const onSuggestHandler = (text) => {
        setText(text)
        setSuggestions([])
        }

    useEffect(() => {
        loadCustomers()
    }, [])


    const handleSubmitAddModal = () => {
        const formData = new FormData();
        formData.append("name", text);
        formData.append("hidden", false);
        Api.post(AGENCIES_URL + props.agency.slug + "/customers/", formData).then(res => {
            props.onClick()
            setShowAddModal(false)
        }).catch(err => {
            alert(JSON.stringify(err?.response?.data))

        });
    }

    const handleSubmitModifyModal = () => {
        const formData = new FormData();
        formData.append("name", text);

        Api.patch(AGENCIES_URL + props.agency.slug + "/customers/" + customerSlug + "/", formData).then(res => {
            props.onClick()
            setShowModifyModal(false)
        }).catch(err => {
            alert(JSON.stringify(err?.response?.data))

        });
    }

    const handleShowModifyModal = (name, slug) => {
        setText(name)
        setCustomerSlug(slug)
        setShowModifyModal(true)
    }

    const handleShowAddModal = () => {
        setShowAddModal(true)
        setText('')
        setSuggestions([])

    }
    const handleDeleteCustomer = (name, slug) => {
        Api.delete(AGENCIES_URL + props.agency.slug + "/customers/" + slug + "/",).then(res => {
            props.onClick()
        }).catch(err => {
            alert(JSON.stringify(err?.response?.data))

        });
    }
    return (
        <>

            <form>
                <div className="form-col">
                    <label className="col-sm-2 col-form-label" htmlFor="inputState">Principaux clients</label>

                    <div className="form-group row">
                        {props?.customers?.map((cus, index) => (
                            <div className="col-6 mb-4">
                                <input className="form-control mb-2" id="exampleFormControlTextarea1" disabled name="Notes" placeholder="Nom du client" value={cus?.name} onChange={(e) => props.onChange(e.target.value, index)} />
                                <Button variant="outline-danger" size="sm" onClick={() => handleDeleteCustomer(cus?.name, cus?.slug)} className="pitchichi">Supprimer</Button>
                            </div>
                        ))}

                    </div>
                </div>
            </form>


            <Button variant="outline-primary" className="pitchichi2 px-5 mr-4 mt-5" onClick={() => handleShowAddModal()}>Ajouter</Button>
            <Modal size="lg" show={showAddModal} onHide={handleCloseAddModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Ajouter un client</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmitAddModal}>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label" htmlFor="inputState">Nom du client</label>
                            <div className="col-sm-8">
                                <input type="text" className="form-control" id="" onChange={e => onChangeHandler(e.target.value)} value={text} />
                                {suggestions && suggestions.map((suggestion, i) =>
                                    <div className="suggestion col-md-12 justify-content-md-center" onClick={() => onSuggestHandler(suggestion.name)}>{suggestion.name}</div>
                                )}
                            </div>
                        </div>

                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseAddModal}>
                        Annuler
                    </Button>
                    <Button variant="primary" onClick={handleSubmitAddModal}>
                        Valider
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal size="lg" show={showModifyModal} onHide={handleCloseModifyModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Modifier client</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmitModifyModal}>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label" htmlFor="inputState">Nom du client</label>
                            <div className="col-sm-8">
                                <input type="text" className="form-control" id="" onChange={e => onChangeHandler(e.target.value)} value={text} />
                                {suggestions && suggestions.map((suggestion, i) =>
                                    <div className="suggestion col-md-12 justify-content-md-center" onClick={() => onSuggestHandler(suggestion.name)}>{suggestion.name}</div>
                                )}
                            </div>
                        </div>

                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModifyModal}>
                        Annuler
                    </Button>
                    <Button variant="primary" onClick={handleSubmitModifyModal}>
                        Valider
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    );
}

