import './../App.scss';
import React from "react";
import Button from 'react-bootstrap/Button';


export default function TabKeyprofile(props) {

    return (
        <>

            <form>
                <div className="form-col">
                    <div className="form-group row">
                        <label className="col-sm-4 col-form-label" htmlFor="inputState">Directeur(s) de la création</label>
                        <div className="col-sm-5">
                            <input type="text" className="form-control" id="inputPassword3" placeholder="Séparer d'une virgule" onChange={(e) => props.onChangeCreationDirectors(e.target.value)} value={props.creationDirectors} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-4 col-form-label" htmlFor="inputState">Directeur(s) de la stratégie</label>
                        <div className="col-sm-5">
                            <input type="text" className="form-control" id="inputPassword3" placeholder="Séparer d'une virgule" onChange={(e) => props.onChangeStrategyDirectors(e.target.value)} value={props.strategyDirectors} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-4 col-form-label" htmlFor="inputState">Directeur(s) de la production</label>
                        <div className="col-sm-5">
                            <input type="text" className="form-control" id="inputPassword3" placeholder="Séparer d'une virgule" onChange={(e) => props.onChangeProductionDirectors(e.target.value)} value={props.productionDirectors} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-4 col-form-label" htmlFor="inputState">Directeur(s) commercial/commerciaux</label>
                        <div className="col-sm-5">
                            <input type="text" className="form-control" id="inputPassword3" placeholder="Séparer d'une virgule" onChange={(e) => props.onChangeNewbizDirectors(e.target.value)} value={props.newbizDirectors} />
                        </div>
                    </div>
                </div>
            </form>
            <div className="d-flex mt-5 align-items-center">
                <Button variant="outline-primary" className="pitchichi2 px-5 " onClick={props.onClick}>Valider</Button>
                {props.success && <span variant="success" className="pitchichi2 ml-3 text-success">Mise à jour effectuée</span>}
            </div>

        </>
    );
}

