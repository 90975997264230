const dotenv = require("dotenv")
dotenv.config()

export const API_URL = (typeof process.env.REACT_APP_API_URL === 'undefined') ? "https://api.preprod.pitchichi.com" : process.env.REACT_APP_API_URL;

export const CUSTOMERS_URL = API_URL + "/customers/";
export const AGENCIES_URL = API_URL + "/agencies/";
export const PITCHICHI_CASES_URL = API_URL + "/customer-cases/";
export const PRESS_URL = API_URL + "/press/";
export const TESTIMONIALS_URL = API_URL + "/testimonials/";
export const USERS_URL = API_URL + "/users/";
export const EMAIL_VERIFICATION_URL = API_URL + "/auth/registration/verify-email/";
export const RESET_PASSWORD_URL = API_URL + "/auth/password/reset/confirm/";
export const CUSTOMER_AVAILABLE_URL = API_URL + "/customers/availables/";
export const FORGOT_PASSWORD_URL = API_URL + "/auth/password/reset/";


export const DELETE_AGENCY_URL = (slug) => {
  return AGENCIES_URL + slug
}
export const TEAM_PICTURE_URL = (slug) => {
  return AGENCIES_URL + slug + "/team-picture/"
}
export const OFFICE_PICTURE_URL = (slug) => {
  return AGENCIES_URL + slug + "/office-picture/"
}
export const LOGO_PICTURE_URL = (slug) => {
  return AGENCIES_URL + slug + "/logo/"
}

export const PRESS_LOGO_URL = (id) => {
  return PRESS_URL + id + "/logo/"
}

export const CUSTOMER_LOGO_URL = (id) => {
  return CUSTOMERS_URL + id + "/logo/"
}

export const GET_USER = (id) => {
  return USERS_URL + id + "/"
}


export const CUSTOMER_CASES_URL = (slug) => {
  return AGENCIES_URL + slug + "/customer-cases/"
}

export const DELETE_CUSTOMER_CASES_URL = (agencySlug, customerSlug) => {
  return AGENCIES_URL + agencySlug + "/customer-cases/" + customerSlug + "/"
}

export const CUSTOMER_CASES_IMAGE_URL = (agencySlug, customerSlug) => {
  return AGENCIES_URL + agencySlug + "/customer-cases/" + customerSlug + "/images/"
}

export const PATCH_AGENCY = (slug) => {
  return AGENCIES_URL + slug + "/"
}

export const IMAGES_PITCHICHI_CASES_URL = (pitchichiCase) => {
  return PITCHICHI_CASES_URL + pitchichiCase + "/thumbnail/"
}

export const PITCHICHI_CASE_URL = (pitchichiCase) => {
  return PITCHICHI_CASES_URL + pitchichiCase + "/"
}

export const CASE_BANNER_PICTURE_URL = (slug) => {
  return PITCHICHI_CASES_URL + slug + "/banner/"
}

export const CASE_THUMBNAIL_PICTURE_URL = (slug) => {
  return PITCHICHI_CASES_URL + slug + "/thumbnail/"
}

export const CASE_DELETE_IMAGE_URL = (slug, id) => {
  return PITCHICHI_CASES_URL + slug + "/images/" + id + "/"
}

export const CASE_ADD_IMAGE_URL = (slug) => {
  return PITCHICHI_CASES_URL + slug + "/images/"
}






