import React, { Component } from "react";
import axios from 'axios';
import { LinkContainer } from 'react-router-bootstrap'

import { API_URL } from "../util/Constant";
import './SignIn.scss';
import logo from '../Assets/logo-main.svg'
export default class SignIn extends Component {

    constructor() {
        super();
        this.state = {
            email: "",
            password: "",
            errorMessage: undefined,
            isLogging: false
        };
    }

    handleChangeText(e, a) {
        this.setState({
            [e]: a.target.value
        });
        this.setState({
            errorMessage: undefined,
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({
            isLogging: true,
            errorMessage: undefined
        });

        const user = {
            email: this.state.email,
            password: this.state.password,
        }

        axios.post(API_URL + "/auth/login/", user).then(res => {
            if (res.data) {
                localStorage.setItem('accessToken', res.data.access_token);
                localStorage.setItem('refreshToken', res.data.refresh_token);
                localStorage.setItem('isPitchichi', JSON.stringify(res.data.user.is_pitchichi));
                this.props.history.push("/");
            }
        }).catch((err) => {
            alert(JSON.stringify(err?.response?.data))
        });
    }


    render() {
        return (
            <>
                <div className="wrapper text-center">
                    <form className="form-signin" onSubmit={this.handleSubmit.bind(this)} method="POST">
                        <img className="mb-4" src={logo} alt="" width="250" />
                        <label htmlFor="email" className="font-weight-bold sr-only">Email</label>
                        <input autoComplete="off" id="email" className="form-control w-100" type="text" onChange={this.handleChangeText.bind(this, "email")} placeholder="Email" required />
                        <label for="inputPassword" className="sr-only">Password</label>
                        <input autoComplete="off" id="pwd" className="form-control" type="password" onChange={this.handleChangeText.bind(this, "password")} placeholder="Mot de passe" required />

                        <button className="btn btn-lg btn-primary btn-block mt-3" type="submit">Se connecter</button>
                        {this.state.errorMessage && <div><small className="text-danger mt-2">Nom d'utilisateur ou mot de passe incorrect</small></div>}
                        <div className="text-primary mt-5">
                            <a href="/signup"><small>S'enregistrer</small></a>
                        </div>
                        <div className="text-primary mt-1">
                            <a href="/forgot-email"><small>Mot de passe oublié?</small></a>
                        </div>

                        <p className="mt-5 mb-3 text-muted">&copy; PITCHICHI 2022</p>
                    </form>
                </div>

                {/* <form className="w-100" onSubmit={this.handleSubmit.bind(this)} method="POST">
                            <div className="form-group">
                                <label htmlFor="email" className="font-weight-bold">Nom d'utilisateur</label>
                                <input autoComplete="off" id="email" className="form-control w-100" type="text" onChange={this.handleChangeText.bind(this, "userName")} placeholder="Nom d'utilisateur" required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="pwd" className="font-weight-bold">Mot de passe</label>
                                <input autoComplete="off" id="pwd" className="form-control" type="password" onChange={this.handleChangeText.bind(this, "password")} placeholder="Mot de passe" required />
                            </div>
                            <button type="submit" disabled={!this.state.userName || !this.state.password} className="btn btn-info" onClick={this.handleSubmit.bind(this)}>Connexion</button>
                            {this.state.errorMessage && <div><small className="text-danger mt-2">Nom d'utilisateur ou mot de passe incorrect</small></div>}

                        </form> */}


                {/* <ApiVersion /> */}
            </>
        );
    }
}