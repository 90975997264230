import './../App.scss';
import React from "react";
import nextId from 'react-id-generator';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

export default function TabCategories(props) {
    return (
        <>
            {props?.characteristics && props?.characteristics?.map((ch, index1) => (
                <div key={nextId()}>
                    <h1>{ch?.name}</h1>
                    {ch?.characteristics?.map((chc, index2) => (
                        <div key={nextId()}>
                            <div className="d-flex">
                                <div className="mr-2">{chc?.name}</div>
                                <Form>
                                    <Form.Check
                                        type="switch"
                                        id="custom-switch"
                                        checked={chc.value}
                                        onChange={(e) => props.onClick2(chc.value, index1, index2)}
                                    />

                                </Form>
                            </div>
                        </div>))}

                </div>
            ))}
            <Form.Group as={Row} className="mb-3" onChange={(e) => props.onClick3(e.target.value)} >
                <Form.Label as="legend" column sm={12}>
                    <h1>Classement</h1>
                </Form.Label>
                <Col sm={9}>
                    <Form.Check
                        value="BP"
                        type="radio"
                        checked={"BP" === props?.orderingClass}
                        label="Partenaire-Boosté"
                        name="type"
                        id="formHorizontalRadios1"
                    />
                    <Form.Check
                        value="HP"
                        checked={"HP" === props?.orderingClass}
                        type="radio"
                        label="Partenaire-Vitrine"
                        name="type"
                        id="formHorizontalRadios2"
                    />
                    <Form.Check
                        value="P"
                        checked={"P" === props?.orderingClass}
                        type="radio"
                        label="Partenaire-Non Vitrine"
                        name="type"
                        id="formHorizontalRadios3"
                    />
                    <Form.Check
                        value="H"
                        checked={"H" === props?.orderingClass}
                        type="radio"
                        label="Non-partenaire-Vitrine"
                        name="type"
                        id="formHorizontalRadios3"
                    />
                    <Form.Check
                        value="S"
                        checked={"S" === props.orderingClass}
                        type="radio"
                        label="Non-partenaire-Non-Vitrine "
                        name="type"
                        id="formHorizontalRadios3"
                    />
                </Col>
            </Form.Group>

        </>
    );
}

