
import { API_URL } from "./Constant";
import axios from "axios";
import { isTokenExpired } from "./TokenUtil";

const CancelToken = axios.CancelToken;
let cancel;

export async function getHeaders() {

    let accessToken = localStorage.getItem('accessToken');

    if (accessToken != null && !isTokenExpired(accessToken)) {
        return { Authorization : "Bearer " + accessToken };
    } else {
        let refreshToken = localStorage.getItem('refreshToken');

        if (refreshToken != null && !isTokenExpired(refreshToken)) {

            const res = await axios.post(API_URL + '/auth/token/refresh/', {refresh : refreshToken});

            if (res && res.data) {
                localStorage.setItem('accessToken', res.data.access);
                return { Authorization : "Bearer " + res.data.access };
            } 
        }
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        window.location = "/";
    }
}

export async function getApiVersion() {
    let apiVersion = await localStorage.getItem('apiVersion');
    
    if (apiVersion == null) {
        apiVersion = "";
    } else {
        apiVersion += "/"
    }
    return apiVersion;
}

function preRequest() {
    return getHeaders().then(headers => {
        return getApiVersion().then(apiVersion => {
            return axios.create({
                baseURL: API_URL + apiVersion,
                headers : headers
            });
        });
       
    })
}

function getRequest(url, params) {
    return preRequest().then(instance => {
        return instance.get(url,params, {cancelToken : new CancelToken(function executor(c) {
                cancel = c;
            })
        });
    })
}

function postRequest(url, payload, config) {
    return preRequest().then(instance => {
        return instance.post(url, payload, {cancelToken : new CancelToken(function executor(c) {
                cancel = c;
            })
        });
    })
}

function patchRequest(url, payload, config) {
    return preRequest().then(instance => {
        return instance.patch(url, payload, {cancelToken : new CancelToken(function executor(c) {
                cancel = c;
            })
        });
    })
}

function deleteRequest(url, payload, config) {
    return preRequest().then(instance => {
        return instance.delete(url, payload, {cancelToken : new CancelToken(function executor(c) {
                cancel = c;
            })
        });
    })
}

function cancelRequest() {
    cancel();
}

export default {
    get : getRequest,
    post : postRequest,
    cancel : cancelRequest,
    delete : deleteRequest,
    patch: patchRequest
}