import React, { useState, useEffect } from "react";
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import { FORGOT_PASSWORD_URL } from '../util/Constant'
import './ForgotPassword.scss';
import logo from '../Assets/logo-main.svg'
import Button from 'react-bootstrap/Button';
import { useHistory } from "react-router-dom";

export default function ForgotPassword(props) {
    const [password1, setpassword1] = useState(null)
    const [error, setError] = useState()
    const [showModal, setShowModal] = useState(false)
    const history = useHistory();
    const handleCloseModal = () => {
        setShowModal(false)
        history.push("/");
    };

    const handlePasswordReset = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("email", password1);
        axios.post(FORGOT_PASSWORD_URL, formData).then(res => {
            setShowModal(true)
        }).catch(err => {
            setError(err.response.data.email[0])
        });
    }

    const setEmail = (e) => {
        setError()
        setpassword1(e.target.value)
    }

    return (
        <div className="wrapper-reset text-center">
            <form className="form-reset" onSubmit={handlePasswordReset}>
                <img className="mb-4" src={logo} alt="" width="250" />
                <div className="form-group row">
                    <label className="col-3 col-form-label" htmlFor="inputName">E-mail</label>
                    <div className="col-8">
                        <input type="text" className="form-control" id="new_password1" onChange={(e) => setEmail(e)} value={password1} />
                        <small id="passwordHelpBlock" class="form-text text-muted">
                        {error}
                        </small>
                    </div>
                </div>
                <button className="btn btn-lg btn-primary btn-block mt-3" disabled={!password1} type="submit">Réinitialiser votre mot de passe</button>
                <div className="text-primary mt-1">
                    <a href="/"><small>Retour à la page de connexion</small></a>
                </div>
            </form>
            <Modal size="lg" show={showModal} onHide={handleCloseModal} centered>

                <Modal.Body>
                   Si l'email que vous avez renseigné existe, vous recevrez d'ici peu un lien vous permettant de réinitialiser votre mot de passe.

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        OK
                    </Button>

                </Modal.Footer>
            </Modal>
        </div>
    );

}





