import './App.scss';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';


import { Route, Switch } from 'react-router-dom';


import SignIn from './Views/SignIn';
import SignUp  from "./Views/SignUp";

import Layout from './Navigation/Layout';
import ConfirmedMail from './Views/ConfirmedMail';
import ResetPassword from './Views/ResetPassword';
import ForgotPassword from './Views/ForgotPassword';

function App() {
  

  return (
    <Router>
        <Switch>
            <Route exact path="/signin" component={SignIn} />
            <Route exact path='/signup' component={SignUp} />
            <Route exact path='/email-confirmation/:slug' component={ConfirmedMail} />
            <Route exact path='/reset-password/:uid/:token' component={ResetPassword} />
            <Route exact path='/forgot-email' component={ForgotPassword} />
            <Route path="/" component={Layout} />
        </Switch>
    </Router>
  );
}

export default App;
