import './../App.scss';
import React, { useState } from 'react';
import nextId from 'react-id-generator';

import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';

import Button from 'react-bootstrap/Button';
import Api from '../util/Api'
import { CUSTOMER_CASES_URL, CUSTOMER_CASES_IMAGE_URL, DELETE_CUSTOMER_CASES_URL } from '../util/Constant'



export default function TabReferencies(props) {
    const [showAddReferenceModal, setAddReferenceModal] = useState(false);
    const [showAddImageModal, setAddImageModal] = useState(false);

    const [selectedFile, setSelectedFile] = useState("");
    const [title, setTitle] = useState("");
    const [customerSlug, setCustomerSlug] = useState("")

    const handleCloseAddReferenceModal = () => setAddReferenceModal(false);
    const handleCloseAddImageModal = () => setAddImageModal(false);

    const handleShowAddReferenceModal = (slug) => {
        setAddReferenceModal(true)
    };
    const handleShowAddImageModal = (slug) => {
        setCustomerSlug(slug)
        setAddImageModal(true)
    };
    const fileChangedHandler = (event) => {
        setSelectedFile(event.target.files[0])
    }



    ////// ADD REFERENCE

    const [customer, setCustomer] = useState("");
    const [name, setName] = useState("");

    const handleSubmitAddReferenceModal = event => {
        const formData = new FormData();
        formData.append("testimony", name);
        formData.append("customer", customer);
        formData.append("name", name);

        event.preventDefault();
        Api.post(CUSTOMER_CASES_URL(props.agency.slug), formData).then(res => {
            setAddReferenceModal(false)
            props.onClick()

        }).catch(err => {
            alert(JSON.stringify(err?.response?.data))
        });
    }

    const handleSubmitAddImageModal = event => {
        const formData = new FormData();
        formData.append("title", title);
        formData.append("file", selectedFile);
        event.preventDefault();
        Api.post(CUSTOMER_CASES_IMAGE_URL(props.agency.slug, customerSlug), formData).then(res => {
            setAddImageModal(false)
            props.onClick()
        }).catch(err => {
            alert(JSON.stringify(err?.response?.data))
        });
    }

    const deleteReference = slug => {

        Api.delete(DELETE_CUSTOMER_CASES_URL(props.agency.slug, slug)).then(res => {
            props.onClick()
        }).catch(err => {
            alert(JSON.stringify(err?.response?.data))
        });
    }



    return (
        <>
            {props?.customerCases && props?.customerCases?.map((cc) => (
                <div key={nextId()}>
                    <Card style={{ width: '100%' }} className="mb-5">
                        {cc?.images?.map((image) => (
                            <Card.Img key={nextId()} variant="top" className="reference" src={image?.file} />
                        ))}
                        <Card.Body>
                            <Card.Title>{cc?.customer}</Card.Title>
                            <Card.Text>
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label" htmlFor="name">Nom de la campagne</label>
                                    <div className="col-sm-4">
                                        <input type="text" className="form-control" id="" name="testimony" value={cc?.name} readOnly />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label" htmlFor="name">Client</label>
                                    <div className="col-sm-4">
                                        <input type="text" className="form-control" id="" name="testimony" value={cc?.customer} readOnly />
                                    </div>
                                </div>
                            </Card.Text>
                            {(cc?.images?.length === 0) && <Button className="mr-4" variant="primary" onClick={() => handleShowAddImageModal(cc.slug)}>Ajouter une image</Button>}
                            <Button variant="primary" onClick={() => deleteReference(cc.slug)}>Supprimer la référence</Button>
                        </Card.Body>
                    </Card>
                </div>
            ))}

            <div className="btn-group mt-5">
                                { props?.customerCases.length <= 9 && <Button variant="outline-primary" type="button" onClick={() => handleShowAddReferenceModal()} className="pitchichi2 px-5">Ajouter une référence</Button>}

            </div>

            <Modal size="lg" show={showAddReferenceModal} onHide={handleCloseAddReferenceModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Ajouter une référence</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmitAddReferenceModal}>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label" for="inputState">Nom de la campagne</label>
                            <div className="col-sm-4">
                                <input type="text" className="form-control" id="" onChange={(e) => setName(e.target.value)} value={name} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label" for="inputState">Client</label>
                            <div className="col-sm-4">
                                <input type="text" className="form-control" id="" onChange={(e) => setCustomer(e.target.value)} value={customer} />
                            </div>
                        </div>


                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseAddReferenceModal}>
                        Annuler
                    </Button>
                    <Button variant="primary" onClick={handleSubmitAddReferenceModal}>
                        Ajouter
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showAddImageModal} onHide={handleCloseAddImageModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Ajouter une image</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmitAddImageModal}>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label" for="inputState">Fichier</label>
                            <div className="col-sm-4">
                                <input type="file" onChange={fileChangedHandler} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label" for="inputState">Titre</label>
                            <div className="col-sm-4">
                                <input type="text" className="form-control" id="" onChange={(e) => setTitle(e.target.value)} value={title} />
                            </div>
                        </div>

                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseAddImageModal}>
                        Annuler
                    </Button>
                    <Button variant="primary" onClick={handleSubmitAddImageModal}>
                        Ajouter
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

