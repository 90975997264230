import './../App.scss';
import React from "react";
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import RangeSlider from 'react-bootstrap-range-slider';
import nextId from 'react-id-generator';


export default function TabNotes(props) {
    console.log(props)

    const summup = () => {
        let scoreTotal = 0;
        let length = 5;
        props.scores.forEach(({ grade }) => scoreTotal += grade);
        props.scores.forEach(function (grade, idx, array) {
            if (idx === array.length - 1) {
                scoreTotal -= grade.grade
            }
        });
        return Math.round((scoreTotal / length) * 100) / 100
    }
    return (
        <>

            <form>
                <div className="form-col">

                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label" htmlFor="inputState">Notes 1</label>
                        <div className="col-sm-10">
                            <textarea className="form-control" id="exampleFormControlTextarea1" name="Notes" defaultValue={props.internalNotes} onChange={(e) => props.onChange(e.target.value)} rows="5" />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label" htmlFor="inputState2">Notes 2</label>
                        <div className="col-sm-10">
                            <textarea className="form-control" id="exampleFormControlTextarea2" name="Notes1" defaultValue={props.internalNotes1} onChange={(e) => props.onChange1(e.target.value)} rows="5" />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label" htmlFor="inputState3">Notes 3</label>
                        <div className="col-sm-10">
                            <textarea className="form-control" id="exampleFormControlTextarea3" name="Notes2" defaultValue={props.internalNotes2} onChange={(e) => props.onChange2(e.target.value)} rows="5" />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label" htmlFor="inputState">Note globale</label>
                        <div className="col-sm-1">
                            <input className="form-control text-center" id="exampleFormControlTextarea1" name="Note globale" disabled value={summup()} />
                        </div>
                    </div>
                    <Form>
                        {props?.scores.map((score, index) => (
                            <>
                                <Form.Group as={Row} key={nextId()}>

                                    <Form.Label as="legend" column sm={2}>
                                        {score.label}
                                    </Form.Label>
                                    <Col xs="4">
                                        <RangeSlider
                                            step={1}
                                            min={1}
                                            max={10}
                                            variant='success'
                                            tooltip='off'
                                            onChange={e => props.onSwitchScores(e.target.value, index)}
                                            defaultValue={score.grade}
                                        />
                                    </Col>
                                    <Col xs="1">
                                        <Form.Control className="text-center" value={score.grade} />
                                    </Col>

                                </Form.Group>
                            </>
                        ))}


                    </Form>


                </div>
            </form>

            <div className="d-flex mt-5 align-items-center">
                <Button variant="outline-primary" onClick={props.onClick4} className="pitchichi2 px-5">Valider</Button>
                {props.success && <span variant="success" className="pitchichi2 ml-3 text-success">Mise à jour effectuée</span>}
            </div>

        </>
    );
}

