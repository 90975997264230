import './../App.scss';
import axios from 'axios'
import React, { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';

import Modal from 'react-bootstrap/Modal';
import Api from '../util/Api'
import { CUSTOMERS_URL, CUSTOMER_LOGO_URL } from '../util/Constant'

export default function Customerlogo() {

  const [customer, setCustomer] = useState([])
  const [customerSlug, setSlug] = useState('')
  const [showLogoModal, setShowLogoModal] = useState(false)
  const [showAddModal, setShowAddModal] = useState(false)

  const [selectedFile, setSelectedFile] = useState("");
  const [title, setTitle] = useState("");
  const [customerName, setCustomerName] = useState("");

  const handleShowLogoModal = (id) => {
    setShowLogoModal(true)
    setSlug(id)
  };

  const handleCloseLogoModal = () => {
    setShowLogoModal(false)
  }

  const handleSubmitLogoModal = event => {
    const formData = new FormData();
    formData.append("title", title);
    formData.append("file", selectedFile);
    event.preventDefault();
    Api.post(CUSTOMER_LOGO_URL(customerSlug), formData).then(res => {
      setShowLogoModal(false)
      getCustomer()
    }).catch(err => {
      alert(JSON.stringify(err?.response?.data))
    });
  }

  const fileChangedHandler = (event) => {
    setSelectedFile(event.target.files[0])
  }

  const handleDeleteLogo = (id) => {
    Api.delete(CUSTOMER_LOGO_URL(id)).then(res => {
      getCustomer()
    }).catch(err => {
      alert(JSON.stringify(err?.response?.data))
    });
  }






  const handleCloseAddModal = () => {
    setShowAddModal(false)
  }

  const handleSubmitAddModal = event => {
    const formData = new FormData();
    formData.append("name", customerName);
    event.preventDefault();
    Api.post(CUSTOMERS_URL, formData).then(res => {
      setShowAddModal(false)
      getCustomer()
    }).catch(err => {
      alert(JSON.stringify(err?.response?.data))
    });
  }

  const handleShowAddModal = () => {
    setCustomerName("")
    setSlug("")
    setShowAddModal(true)
  }


  const handleDeleteCase = (slug) => {
    Api.delete(CUSTOMERS_URL + (slug) + '/').then(res => {
      getCustomer()
    }).catch(err => {
      alert(JSON.stringify(err?.response?.data))
    });
  }

  const getCustomer = () => {
    Api.get(CUSTOMERS_URL)
      .then((response) => {
        setCustomer(response.data)
      })
      .catch(err => alert(JSON.stringify(err?.response?.data)))
  }

  const handleSwicthPlateformView = (e, slug) => {
    const formData = new FormData();
    formData.append("displayed_on_platform_page", e);
    Api.patch(CUSTOMERS_URL + slug + "/", formData).then(res => {
      getCustomer()
    }).catch(err => {
      alert(JSON.stringify(err?.response?.data))
    });
  }

  const handleSwicthConsultingView = (e, slug) => {
    const formData = new FormData();
    formData.append("displayed_on_consulting_page", e);
    Api.patch(CUSTOMERS_URL + slug + "/", formData).then(res => {
      getCustomer()
    }).catch(err => {
      alert(JSON.stringify(err?.response?.data))
    });
  }

  const plateformCaseCount = () => {
    let count = 0;
    for (const obj of customer) {
      if (obj.displayed_on_platform_page === true) count++;
    }
    return count
  }

  const consultingCaseCount = () => {
    let count = 0;
    for (const obj of customer) {
      if (obj.displayed_on_consulting_page === true) count++;
    }
    return count
  }

  useEffect(() => {
    getCustomer();


  }, []);

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <div className="btn-toolbar mb-2 mb-md-0 d-flex align-items-center">
          <div className="btn-group mr-2">
            <Button variant="primary" onClick={() => handleShowAddModal()}>Ajouter un client</Button>
          </div>
          <div className="mx-5">Côté plateforme: {plateformCaseCount()}</div>
          <div >Côté cabinet: {consultingCaseCount()}</div>

        </div>
      </div>
      <Row className="mt-3">
        {customer && customer.map((c) => (
          <Col xs={3} className="mb-3" key={c.slug}>
            <Card >
              <Card.Img className="p-3" src={c?.logo?.file} style={{ height: '150px' }} />
              <Card.Body>
                <Card.Text>
                  <strong>Nom:</strong> {c.name}
                </Card.Text>

                <div className="d-flex ">
                  <DropdownButton variant="outline-warning" size="sm" className="rounded" title="Actions">
                    {c.logo ? <Dropdown.Item onClick={() => handleDeleteLogo(c.slug)}>Supprimer le logo</Dropdown.Item> : <Dropdown.Item onClick={() => handleShowLogoModal(c.slug)}>Ajouter un logo</Dropdown.Item>}

                  </DropdownButton>
                  <Button variant="outline-danger" size="sm" className="pitchichi ml-2" onClick={() => handleDeleteCase(c.slug)}>Supprimer</Button>

                </div>
                <Form className="mt-3">
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    label="Côté plateforme"
                    checked={c.displayed_on_platform_page}
                    onChange={(e) => handleSwicthPlateformView(e.target.checked, c.slug)}

                  />
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    checked={c.displayed_on_consulting_page}
                    onChange={(e) => handleSwicthConsultingView(e.target.checked, c.slug)}
                    label="Côté cabinet"
                  />
                </Form>


              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      <Modal show={showLogoModal} onHide={handleCloseLogoModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Ajouter un logo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmitLogoModal}>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label" for="inputState">Fichier</label>
              <div className="col-sm-4">
                <input type="file" onChange={fileChangedHandler} />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label" for="inputState">Titre</label>
              <div className="col-sm-4">
                <input type="text" className="form-control" id="" onChange={(e) => setTitle(e.target.value)} value={title} />
              </div>
            </div>

          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseLogoModal}>
            Annuler
          </Button>
          <Button variant="primary" onClick={handleSubmitLogoModal}>
            Ajouter
          </Button>
        </Modal.Footer>
      </Modal>




      <Modal size="lg" show={showAddModal} onHide={handleCloseAddModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Ajouter un client</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmitAddModal}>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label" for="inputState">Nom</label>
              <div className="col-sm-10">
                <input type="text" className="form-control" id="" onChange={(e) => setCustomerName(e.target.value)} value={customerName} />
              </div>
            </div>


          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseAddModal}>
            Annuler
          </Button>
          <Button variant="primary" onClick={handleSubmitAddModal}>
            Ajouter
          </Button>
        </Modal.Footer>
      </Modal>



    </>
  );
}

