import './../App.scss';
import React, { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ReactPlayer from 'react-player'


import Modal from 'react-bootstrap/Modal';
import Api from '../util/Api'
import { PITCHICHI_CASE_URL, CASE_BANNER_PICTURE_URL, CASE_THUMBNAIL_PICTURE_URL, CASE_DELETE_IMAGE_URL, CASE_ADD_IMAGE_URL } from '../util/Constant'

export default function Case(props) {
  const [showError, setShowError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const [showImageModal, setShowImageModal] = useState(false)
  const [showVideoModal, setShowVideoModal] = useState(false)
  const [videoUrl, setVideoUrl] = useState("")
  const [videoUrls, setVideoUrls] = useState([])

  const [selectedFile, setSelectedFile] = useState("");
  const [title, setTitle] = useState("")
  const [imageId, setImageId] = useState("")

  const [thisCase, setThisCase] = useState({})
  const [customer, setCustomer] = useState("")
  const [name, setName] = useState("")
  const [description, setDescription] = useState("")
  const [year, setYear] = useState("")
  const [fromCase, setFromCase] = useState("");
  const [unreferencedAgencyName, setUnreferencedAgencyName] = useState("");

  const getCase = () => {
    Api.get(PITCHICHI_CASE_URL(props.match.params.slug))
      .then((response) => {
        setThisCase(response.data)
        setCustomer(response.data.customer)
        setName(response.data.name)
        setDescription(response.data.description)
        setYear(response.data.year)
        setVideoUrls(response.data.video_urls)
        setUnreferencedAgencyName(response.data.unreferenced_agency_name)
      }).catch(() => {
        console.log("Erreur lors du retrait des agences")
      })
  }

  const handleSubmitModify = () => {
    const formData = new FormData();

    formData.append("customer", customer);
    formData.append("name", name);
    formData.append("year", year);
    formData.append("description", description);
    formData.append("unreferenced_agency_name", unreferencedAgencyName);

    Api.patch(PITCHICHI_CASE_URL(props.match.params.slug), formData).then(res => {
      getCase();

    }).catch(err => {
      alert(JSON.stringify(err?.response?.data))

    });
  }

  const fileChangedHandler = (event) => {
    setSelectedFile(event.target.files[0])
  }

  const handleShowImageModal = (from, title, id) => {
    setShowImageModal(true)
    setFromCase(from)
    setImageId(id)
    setTitle(title)
  }

  const handleShowVideoModal = (from, title, id) => {
    setShowVideoModal(true)

  }

  const handleCloseImageModal = () => {
    setShowImageModal(false)

  }

  const handleCloseVideoModal = () => {
    setShowVideoModal(false)

  }

  const handleSubmitVideoModal = () => {
    videoUrls.push(videoUrl)
    Api.patch(PITCHICHI_CASE_URL(props.match.params.slug), { video_urls: videoUrls }).then(res => {
      getCase()
      setShowVideoModal(false)

    }).catch(err => {
      alert(JSON.stringify(err?.response?.data))
    });
  }

  const handleDeleteVideo = () => {
    Api.patch(PITCHICHI_CASE_URL(props.match.params.slug), { video_urls: [] }).then(res => {
      getCase()
      setShowVideoModal(false)

    }).catch(err => {
      alert(JSON.stringify(err?.response?.data))
    });
  }

  const handleDeleteImage = (caseDelete, id) => {
    switch (caseDelete) {
      case "thumbnail":
        Api.delete(CASE_THUMBNAIL_PICTURE_URL(props.match.params.slug)).then(res => {
          getCase()
        }).catch(err => {
          alert(JSON.stringify(err?.response?.data))
        });
        break;
      case "banner":
        Api.delete(CASE_BANNER_PICTURE_URL(props.match.params.slug)).then(res => {
          getCase()
        }).catch(err => {
          alert(JSON.stringify(err?.response?.data))
        });
        break;
      case "image":
        Api.delete(CASE_DELETE_IMAGE_URL(props.match.params.slug, id)).then(res => {
          getCase()
        }).catch(err => {
          alert(JSON.stringify(err?.response?.data))
        });
        break;

      default:
        setErrorMessage("erreur")

        break
    }
  }

  const handleSubmitImageModal = () => {
    const formData = new FormData();
    formData.append("title", title);
    if (selectedFile) {
      formData.append("file", selectedFile);

    }
    switch (fromCase) {
      case "thumbnail-modify":
        Api.patch(CASE_THUMBNAIL_PICTURE_URL(props.match.params.slug), formData).then(res => {
          getCase()
          setShowImageModal(false)
        }).catch(err => {
          alert(JSON.stringify(err?.response?.data))
        });
        break;
      case "thumbnail-add":
        Api.post(CASE_THUMBNAIL_PICTURE_URL(props.match.params.slug), formData).then(res => {
          getCase()
          setShowImageModal(false)
        }).catch(err => {
          alert(JSON.stringify(err?.response?.data))
        });
        break;
      case "banner-modify":
        Api.patch(CASE_BANNER_PICTURE_URL(props.match.params.slug), formData).then(res => {
          getCase()
          setShowImageModal(false)
        }).catch(err => {
          alert(JSON.stringify(err?.response?.data))
        });
        break;
      case "banner-add":
        Api.post(CASE_BANNER_PICTURE_URL(props.match.params.slug), formData).then(res => {
          getCase()
          setShowImageModal(false)
        }).catch(err => {
          alert(JSON.stringify(err?.response?.data))
        });
        break;

      case "image-add":
        Api.post(CASE_ADD_IMAGE_URL(props.match.params.slug), formData).then(res => {
          getCase()
          setShowImageModal(false)
        }).catch(err => {
          alert(JSON.stringify(err?.response?.data))
        });
        break;
      case "image-modify":
        Api.patch(CASE_DELETE_IMAGE_URL(props.match.params.slug, imageId), formData).then(res => {
          getCase()
          setShowImageModal(false)
        }).catch(err => {
          alert(JSON.stringify(err?.response?.data))
        });
        break;

      default:
        setErrorMessage("erreur")

        break
    }
  }


  useEffect(() => {
    getCase();
  }, []);
  return (
    <>

      <Row className="mt-3">
        <Col className="mb-3">
          <form onSubmit={handleSubmitModify}>
            <div className="form-group row">
              <label className="col-3 col-form-label" htmlFor="inputCustomer">Client</label>
              <div className="col-8">
                <input type="text" className="form-control" id="inputCustomer" onChange={(e) => setCustomer(e.target.value)} value={customer} />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-3 col-form-label" htmlFor="inputName">Nom de la campagne</label>
              <div className="col-8">
                <input type="text" className="form-control" id="inputName" onChange={(e) => setName(e.target.value)} value={name} />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-3 col-form-label" htmlFor="inputName">Année</label>
              <div className="col-8">
                <input type="text" className="form-control" id="inputName" onChange={(e) => setYear(e.target.value)} value={year} />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-3 col-form-label" htmlFor="description">Description *</label>
              <div className="col-8">
                <textarea className="form-control" id="exampleFormControlTextarea1" name="description" onChange={(e) => setDescription(e.target.value)} maxLength="1000" rows="5" value={description}></textarea>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-3 col-form-label" htmlFor="inputName">Projet réalisé par</label>
              <div className="col-8">
                <input type="text" className="form-control" id="inputName" onChange={(e) => setUnreferencedAgencyName(e.target.value)} value={unreferencedAgencyName} />
              </div>
            </div>
            <Button variant="primary" onClick={handleSubmitModify}>
              Modifier
            </Button>

          </form>
        </Col>


      </Row>
      <Row className="mt-5 pb-5">
        <Col xs={6} className="d-flex align-items-center justify-content-between flex-column border">
          <h2>Thumbnail</h2>
          <div style={{ height: 200 }}>
            <img src={thisCase?.thumbnail?.file} width="100%" height="100%" alt="" />
          </div>
          <div>
            {thisCase?.thumbnail?.title}
          </div>
          <div className="mt-4">
            {
              thisCase?.thumbnail
                ?
                <div>
                  <Button variant="outline-primary" onClick={() => handleShowImageModal('thumbnail-modify', thisCase?.thumbnail?.title)} size="sm" className="pitchichi">Modifier</Button>
                  <Button variant="outline-danger" onClick={() => handleDeleteImage('thumbnail')} size="sm" className="ml-2 pitchichi">Supprimer</Button>
                </div>

                :
                <Button variant="outline-primary" onClick={() => handleShowImageModal('thumbnail-add')} size="sm" className="pitchichi">Ajouter</Button>
            }
          </div>

        </Col>
        <Col xs={6} className="d-flex align-items-center justify-content-between flex-column border">
          <h2>Banner</h2>
          <div style={{ height: 200 }}>
            <img src={thisCase?.banner?.file} width="100%" height="100%" alt="" />
          </div>
          <div>
            {thisCase?.banner?.title}
          </div>
          <div className="mt-4">
            {
              thisCase?.banner
                ?
                <div>
                  <Button variant="outline-primary" onClick={() => handleShowImageModal('banner-modify', thisCase?.banner?.title)} size="sm" className="pitchichi">Modifier</Button>
                  <Button variant="outline-danger" onClick={() => handleDeleteImage('banner')} size="sm" className="ml-2 pitchichi">Supprimer</Button>
                </div>

                :
                <Button variant="outline-primary" onClick={() => handleShowImageModal('banner-add')} size="sm" className="pitchichi">Ajouter</Button>
            }
          </div>

        </Col>



        {thisCase?.images?.map((image) => (
          <Col xs={6} className="d-flex align-items-center justify-content-between flex-column mt-5 border">
            <h2>Image</h2>
            <div style={{ height: 200 }}>
              <img src={image.file} width="100%" height="100%" alt="" />
            </div>
            <div>
              {image.title}
            </div>
            <div className="mt-4">
              <div>
                <Button variant="outline-primary" onClick={() => handleShowImageModal('image-modify', image.title, image.id)} size="sm" className="pitchichi">Modifier</Button>
                <Button variant="outline-danger" onClick={() => handleDeleteImage('image', image.id)} size="sm" className="ml-2 pitchichi">Supprimer</Button>
              </div>
            </div>

          </Col>
        ))

        }
        {thisCase?.images?.length < 2 &&
          <Col xs={6} className="d-flex align-items-center justify-content-between flex-column mt-5 border">
            <h2>Image</h2>
            <div style={{ height: 200 }}>
              <img src={thisCase.file} width="100%" height="100%" alt="" />
            </div>
            <div>
              {thisCase.title}
            </div>
            <div className="mt-4">
              <Button variant="outline-primary" onClick={() => handleShowImageModal('image-add')} size="sm" className="pitchichi">Ajouter</Button>

            </div>

          </Col>
        }

        {thisCase?.video_urls?.map((video) => (
          <Col xs={6} className="d-flex align-items-center justify-content-between flex-column mt-5 border" key={video}>
            <h2>Video</h2>
            <div style={{ height: 200 }}>
              <ReactPlayer url={video} width="100%" height="100%" />
            </div>
            <div>
              {video.title}
            </div>
            <div className="mt-4">
              <div>
                <Button variant="outline-primary" onClick={() => handleShowVideoModal('video-modify')} size="sm" className="pitchichi">Modifier</Button>
                <Button variant="outline-danger" onClick={() => handleDeleteVideo('video')} size="sm" className="ml-2 pitchichi">Supprimer</Button>
              </div>
            </div>

          </Col>
        ))

        }
        {thisCase?.video_urls?.length < 2 &&
          <Col xs={6} className="d-flex align-items-center justify-content-between flex-column mt-5 border">
            <h2>Video</h2>
            <div style={{ height: 200 }}>
              <img src={thisCase.file} width="100%" height="100%" alt="" />
            </div>
            <div>
              {thisCase.title}
            </div>
            <div className="mt-4">
              <Button variant="outline-primary" onClick={() => handleShowVideoModal('video-add')} size="sm" className="pitchichi">Ajouter</Button>
            </div>
          </Col>
        }


      </Row>

      <Modal size="lg" show={showImageModal} onHide={handleCloseImageModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Ajouter une image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmitImageModal}>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label" htmlFor="inputState">Fichier</label>
              <div className="col-sm-4">
                <input type="file" onChange={fileChangedHandler} />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label" htmlFor="inputState">Titre</label>
              <div className="col-sm-8">
                <input type="text" className="form-control" id="" onChange={(e) => setTitle(e.target.value)} value={title} />
              </div>
            </div>

          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseImageModal}>
            Annuler
          </Button>
          <Button variant="primary" onClick={handleSubmitImageModal}>
            Valider
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal size="lg" show={showVideoModal} onHide={handleCloseVideoModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Ajouter une Video</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmitVideoModal}>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label" htmlFor="inputState">URL</label>
              <div className="col-sm-8">
                <input type="text" className="form-control" id="" onChange={(e) => setVideoUrl(e.target.value)} value={videoUrl} />
              </div>
            </div>

          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseVideoModal}>
            Annuler
          </Button>
          <Button variant="primary" onClick={handleSubmitVideoModal}>
            Valider
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

