import React, { Component } from "react";
import { Route, Switch, Redirect } from 'react-router-dom';

import SideBar from "./SideBar";
import ProtectedRoute from "../util/ProtectedRoute";
import Agencies from "../Views/Agencies";
import Agency from "../Views/Agency";
import Cases from "../Views/Cases";
import Case from "../Views/Case";
import Press from "../Views/Press";
import Customerlogo from "../Views/CustomerLogo";
import Testimonies from "../Views/Testimonies";
import Users from "../Views/Users";

export default function Layout() {
    
    const handleLogout = () => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('isPitchichi');
        window.location = "/";
    }


        return (
            <div id="wrapper">
                <nav className="navbar navbar-dark sticky-top bg-primary flex-md-nowrap p-0">
                    <a className="navbar-brand col-md-3 col-lg-2 mr-0 px-3" href="/">PITCHICHI</a>
                    <button className="navbar-toggler position-absolute d-md-none collapsed" type="button" data-toggle="collapse" data-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <ul className="navbar-nav px-3">
                        <li className="nav-item text-nowrap">
                            <div className="nav-link" onClick={() => handleLogout()}>Deconnexion</div>
                        </li>
                    </ul>
                </nav>
                <SideBar  />
                <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-md-4">
                    <Switch>
                        <ProtectedRoute exact path='/agencies' component={Agencies} />
                        <ProtectedRoute exact path='/agency/:slug' component={Agency} />
                        <ProtectedRoute exact path='/agency' component={Agency} />
                        <ProtectedRoute exact path='/cases' component={Cases} />
                        <ProtectedRoute exact path='/case/:slug' component={Case} />
                        <ProtectedRoute exact path='/press' component={Press} />
                        <ProtectedRoute exact path='/customerlogo' component={Customerlogo} />
                        <ProtectedRoute exact path='/testimonies' component={Testimonies} />
                        <ProtectedRoute exact path='/users' component={Users} />
                        <Route path="/*" render={() => (
                            <Redirect to="/agencies" />
                        )} />
                    </Switch>

                </main>
            </div>
        );
    }
