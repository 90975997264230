import './../App.scss';
import React from "react";
import Button from 'react-bootstrap/Button';

export default function TabNewbiz(props) {
    return (
        <>

            <form>
                <div className="form-col">
                    <div className="form-group row">
                        <label className="col-sm-3 col-form-label" htmlFor="inputState">Nom</label>
                        <div className="col-sm-4">
                            <input type="text" className="form-control" id="inputPassword3"  onChange={(e) => props.onChangeNewbizName(e.target.value)} value={props.newbizName}/>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-3 col-form-label" htmlFor="inputState">Prénom</label>
                        <div className="col-sm-4">
                            <input type="text" className="form-control" id="inputPassword3"  onChange={(e) => props.onChangeNewbizFirstname(e.target.value)} value={props.newbizFirstname}/>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-3 col-form-label" htmlFor="inputState">Contact téléphonique</label>
                        <div className=" col-sm-5 d-flex">
                            <div className="input-group-prepend">
                                <div className="input-group-text">Tel</div>
                            </div>
                            <input type="text" className="form-control" id="inlineFormInputGroup" placeHolder="Numéro à 10 chiffres" onChange={(e) => props.onChangeNewbizPhone(e.target.value)} value={props.newbizPhone}/>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-3 col-form-label" htmlFor="inputState" >Contact E-mail</label>
                        <div className=" col-sm-5 d-flex">
                            <div className="input-group-prepend">
                                <div className="input-group-text px-3">@</div>
                            </div>
                            <input type="text" className="form-control" id="inlineFormInputGroup" placeHolder="format xxx@yyy.zzz" onChange={(e) => props.onChangeNewbizEmail(e.target.value)} value={props.newbizEmail}/>
                        </div>
                    </div>


                </div>
            </form>
            <div className="d-flex mt-5 align-items-center">
                <Button variant="outline-primary" className="pitchichi2 px-5 " onClick={props.onClick}>Valider</Button>
                {props.success && <span variant="success" className="pitchichi2 ml-3 text-success">Mise à jour effectuée</span>}
            </div>
        </>
    );
}

