import './../App.scss';
import React from "react";
import Button from 'react-bootstrap/Button';


export default function TabLinks(props) {
    return (
        <>
            <form>
                <div className="form-col">
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label" htmlFor="inputState">Facebook</label>
                        <div className="col-sm-5">
                            <input type="text"className="form-control" id="inputPassword3" placeholder="format https://xxx.yyy" onChange={(e) => props.onChangeFacebook(e.target.value)} value={props.facebook} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label" htmlFor="inputState">Instagram</label>
                        <div className="col-sm-5">
                            <input type="text" className="form-control" id="inputPassword3" placeholder="format https://xxx.yyy" onChange={(e) => props.onChangeInstagram(e.target.value)} value={props.insta} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label" htmlFor="inputState">LinkedIn</label>
                        <div className="col-sm-5">
                            <input type="text" className="form-control" id="inputPassword3" placeholder="format https://xxx.yyy" onChange={(e) => props.onChangeSite(e.target.value)} value={props.site} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label" htmlFor="inputState">Twitter</label>
                        <div className="col-sm-5">
                            <input type="text" className="form-control" id="inputPassword3" placeholder="format https://xxx.yyy" onChange={(e) => props.onChangeTwitter(e.target.value)} value={props.twitter} />
                        </div>
                    </div>
                </div>
            </form>
            <div className="d-flex mt-5 align-items-center">
                <Button variant="outline-primary" className="pitchichi2 px-5 " onClick={props.onClick}>Valider</Button>
                {props.success && <span variant="success" className="pitchichi2 ml-3 text-success">Mise à jour effectuée</span>}
            </div>
        </>
    );
}
